import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const PTRNavbar = ({ height }) => {

  // Professional Tax Registration service grid items
  const items = [
    { title: 'Tax Compliance', description: 'Ensures compliance with local tax regulations for businesses and individuals.' },
    { title: 'Legal Requirements', description: 'Meets the legal requirements for professional tax registration in your state.' },
    { title: 'Simplified Process', description: 'Streamlines the process of registering for professional tax with minimal hassle.' },
    { title: 'Expert Assistance', description: 'Provides expert guidance to navigate the registration and filing processes.' },
    { title: 'Timely Updates', description: 'Keeps you updated with any changes in tax laws and regulations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Tax Compliance - Ensures that your business or profession is compliant with local tax regulations.",
    "Legal Requirements - Fulfills legal requirements for professional tax registration, avoiding potential penalties.",
    "Simplified Process - Makes the registration process straightforward and manageable.",
    "Expert Assistance - Access to professional guidance to handle tax-related queries and issues.",
  ];

  const disadvantages = [
    "Costs - There may be costs associated with registration and ongoing compliance.",
    "Administrative Work - Requires ongoing administrative work to stay compliant with tax regulations.",
    "Complexity - Can be complex depending on the state regulations and specific business needs.",
  ];

  const documents = [
    {
      title: "Proof of Identity",
      description: "A valid government-issued identity proof such as Aadhar card or passport is required."
    },
    {
      title: "Proof of Address",
      description: "Proof of residence, such as a utility bill or bank statement, needs to be provided."
    },
    {
      title: "Business Registration Documents",
      description: "Documents related to the business registration, such as the Certificate of Incorporation."
    },
    {
      title: "PAN Card",
      description: "A valid PAN card of the business or individual is needed for verification."
    },
    {
      title: "Professional Details",
      description: "Details related to the nature of business or profession must be provided."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents required for professional tax registration."
    },
    {
      title: "Check Eligibility",
      description: "Ensure that your business or profession meets the eligibility criteria for professional tax registration."
    },
    {
      title: "Apply Online",
      description: "Submit the professional tax registration application online through the relevant state portal."
    },
    {
      title: "Verification",
      description: "Complete any verification processes required by the state authorities."
    },
    {
      title: "Receive Registration",
      description: "After successful verification, receive your professional tax registration certificate."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Professional Tax Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Professional Tax Registration is essential for businesses and individuals to comply with local tax regulations, ensuring that you meet all legal requirements for operating in your state.
            </p>

            <p>Thousands of businesses and professionals trust us for their tax registration needs.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Professional Tax Registration Made Easy</h6>
          <p className='text-center mt-4'>Professional Tax Registration is crucial for compliance with local tax regulations. We make the registration process simple and efficient, ensuring you meet all necessary requirements.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Professional Tax?</h6>

            <p className='mb-4'>Professional Tax is a tax imposed by the state governments on professionals, trades, and employees. It ensures compliance with local regulations and is required for businesses and individuals who earn income through professional activities.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Professional Tax Registration</h6>
            <p className='mb-4'>To register for Professional Tax, you need to meet the following requirements:

              <ul>
                <li><strong>Proof of Identity -</strong> Provide a valid government-issued identity proof such as an Aadhar card or passport.</li>

                <li><strong>Proof of Address -</strong> Submit proof of residence, such as a utility bill or bank statement.</li>

                <li><strong>Business Registration Documents -</strong> Provide documents related to business registration, such as the Certificate of Incorporation.</li>

                <li><strong>PAN Card -</strong> A valid PAN card of the business or individual is needed for verification.</li>

                <li><strong>Professional Details -</strong> Details related to the nature of business or profession must be provided.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Professional Tax Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Professional Tax Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Professional Tax Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Professional Tax Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for Professional Tax Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default PTRNavbar;