import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TrademarkAssignment = ({ height }) => {

  // Trademark Assignment grid items
  const items = [
    { title: 'Legal Framework', description: 'Understand the legal framework governing trademark assignment and the requirements for a valid transfer.' },
    { title: 'Assignment Agreement', description: 'Draft and execute a trademark assignment agreement detailing the terms of the transfer.' },
    { title: 'Recordation Process', description: 'Follow the necessary steps to record the trademark assignment with the relevant authorities.' },
    { title: 'Due Diligence', description: 'Conduct due diligence to ensure that all rights and obligations are properly transferred and documented.' },
    { title: 'Post-Assignment Compliance', description: 'Ensure compliance with any post-assignment requirements to maintain trademark protection.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Clarity of Ownership - Provides clear documentation of ownership transfer for legal and business purposes.",
    "Market Flexibility - Allows for the transfer of trademarks as part of business sales, mergers, or restructuring.",
    "Legal Protection - Ensures that the new owner receives all legal rights and protections associated with the trademark.",
    "Value Addition - Can increase the value of a business by effectively managing and reallocating trademark assets.",
    "Strategic Growth - Facilitates strategic business decisions by allowing the reassignment of trademarks to align with new market strategies.",
  ];

  const disadvantages = [
    "Complexity - Involves complex legal procedures and documentation to ensure a valid and enforceable assignment.",
    "Potential Disputes - Risk of disputes or challenges if the assignment is not properly documented or if rights are contested.",
    "Administrative Burden - Requires careful handling of administrative tasks, including recordation and compliance with legal requirements.",
    "Cost - May involve legal fees and other costs associated with drafting agreements and filing necessary documentation.",
  ];

  const documents = [
    {
      title: "Trademark Assignment Agreement",
      description: "A formal document outlining the terms and conditions of the trademark transfer between parties."
    },
    {
      title: "Proof of Ownership",
      description: "Documentation proving the current owner's rights to the trademark being assigned."
    },
    {
      title: "Assignment Form",
      description: "Completed forms required for recording the assignment with trademark authorities."
    },
    {
      title: "Identification Documents",
      description: "Identification and verification documents for both the assignor and assignee."
    },
    {
      title: "Payment Receipts",
      description: "Receipts or proof of payment for any fees associated with the trademark assignment process."
    }
  ];

  const steps = [
    {
      title: "Prepare the Assignment Agreement",
      description: "Draft a comprehensive trademark assignment agreement detailing the terms, conditions, and parties involved in the transfer."
    },
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents, including proof of ownership, identification, and payment receipts."
    },
    {
      title: "Execute the Agreement",
      description: "Have both parties sign the trademark assignment agreement and ensure that all legal requirements are met."
    },
    {
      title: "Record the Assignment",
      description: "File the assignment with the relevant trademark authorities to update the official records and reflect the new ownership."
    },
    {
      title: "Ensure Compliance",
      description: "Monitor and comply with any post-assignment requirements to maintain the validity and protection of the trademark."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trademark Assignment
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trademark Assignment involves the transfer of ownership of a trademark from one party to another. This process is crucial for businesses involved in mergers, acquisitions, or sales of intellectual property assets.
            </p>

            <p>Ensure a smooth and legally compliant trademark assignment with our expert guidance and support.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trademark Assignment Services</h6>
          <p className='text-center mt-4'>Our Trademark Assignment services ensure a smooth and legally compliant transfer of trademark ownership. Whether you're selling your business or transferring trademark rights, we provide expert support to handle all aspects of the assignment process.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trademark Assignment?</h6>

            <p className='mb-4'>Trademark Assignment is the legal process of transferring ownership rights of a trademark from one party to another. This is often necessary in business transactions such as sales, mergers, or restructuring, ensuring that the new owner obtains all legal rights associated with the trademark.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Trademark Assignment</h6>
            <p className='mb-4'>To be eligible for trademark assignment, ensure the following:
              <ul>
                <li><strong>Valid Ownership -</strong> The current owner must hold valid ownership rights to the trademark being transferred.</li>

                <li><strong>Completed Documentation -</strong> All required documentation and agreements must be prepared and executed.</li>

                <li><strong>Legal Compliance -</strong> The assignment must comply with all legal requirements and regulations governing trademark transfers.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of Trademark Assignment</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Trademark Assignment</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Trademark Assignment</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Trademark Assignment Filing Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

`      </div>
    </>
  );
};

export default TrademarkAssignment;