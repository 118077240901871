import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const EatingHouseLicense = ({ height }) => {

  // Eating House License grid items
  const items = [
    { title: 'Legal Operation', description: 'Ensures that your eating house operates legally and complies with local regulations.' },
    { title: 'Health Compliance', description: 'Helps maintain health and safety standards in your establishment.' },
    { title: 'Customer Assurance', description: 'Builds trust with customers by adhering to legal and safety standards.' },
    { title: 'Avoid Penalties', description: 'Prevents legal issues and penalties by ensuring proper licensing.' },
    { title: 'Streamlined Operations', description: 'Facilitates smooth operation without legal hindrances.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Operation - Guarantees that your eating house is operating within the law and meets all local regulations.",
    "Health Compliance - Ensures adherence to health and safety standards, contributing to a safer dining environment.",
    "Customer Assurance - Builds trust with customers by showcasing compliance with legal and safety standards.",
    "Avoid Penalties - Prevents potential legal issues and fines by maintaining proper licensing.",
    "Streamlined Operations - Facilitates smooth business operations without facing legal or regulatory barriers.",
  ];

  const disadvantages = [
    "Regulatory Compliance - Requires adherence to strict health and safety regulations, which can be challenging to maintain.",
    "Cost of Licensing - Involves costs related to obtaining and renewing the Eating House License.",
    "Inspection Requirements - Subject to periodic inspections by health authorities to ensure ongoing compliance.",
    "Operational Restrictions - Licensing may impose specific operational restrictions and standards.",
    "Penalties for Non-Compliance - Failure to comply with licensing requirements can result in penalties or closure.",
  ];

  const documents = [
    {
      title: "Identity Proof",
      description: "Valid identity proof of the owner, such as Aadhar card, PAN card, or passport."
    },
    {
      title: "Premises Proof",
      description: "Proof of ownership or lease agreement for the premises where the eating house operates."
    },
    {
      title: "Fire Safety Certificate",
      description: "Certificate confirming compliance with fire safety regulations for the premises."
    },
    {
      title: "No Objection Certificate (NOC)",
      description: "NOC from the local police authority to operate an eating house in the specified location."
    },
    {
      title: "Health Trade License",
      description: "License from the local municipal corporation confirming adherence to health and safety regulations."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents including identity proof, premises proof, and fire safety certificate."
    },
    {
      title: "Complete Application",
      description: "Fill out the application form with accurate details about your eating house and its operations."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with required documents to the local authority."
    },
    {
      title: "Inspection",
      description: "Undergo an inspection by health and safety authorities to verify compliance with regulations."
    },
    {
      title: "Receive License",
      description: "Upon successful inspection and verification, receive the Eating House License to operate legally."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Eating House License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtaining an Eating House License is crucial for running a food business legally. Our streamlined process helps you secure the necessary license efficiently and ensures compliance with all regulations.
            </p>

            <p>Ensure smooth operations and legal compliance with our expert assistance in obtaining your Eating House License.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Eating House License Overview</h6>
          <p className='text-center mt-4'>An Eating House License ensures that your food establishment meets all legal and safety requirements, allowing you to operate your eating house smoothly and without legal interruptions.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Eating House License?</h6>

            <p className='mb-4'>An Eating House License is required to legally operate a food establishment such as a restaurant, café, or food truck. It ensures that the premises comply with health, safety, and fire regulations, providing a safe environment for customers.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Eating House License</h6>
            <p className='mb-4'>To be eligible for an Eating House License, you must:

              <ul>
                <li><strong>Provide Identity Proof -</strong> Submit valid identity proof of the owner or business entity.</li>

                <li><strong>Submit Premises Proof -</strong> Provide proof of ownership or lease agreement for the premises.</li>

                <li><strong>Obtain Fire Safety Certificate -</strong> Ensure compliance with fire safety regulations and obtain the necessary certificate.</li>

                <li><strong>Acquire NOC from Police -</strong> Obtain a No Objection Certificate from the local police authority.</li>

                <li><strong>Health Trade License -</strong> Secure a health trade license from the municipal corporation for health and safety compliance.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Eating House License</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Eating House License</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Eating House License</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Eating House License</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for Eating House License</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Eating House License?</h6>
            <p>We offer expert assistance in obtaining your Eating House License, ensuring compliance with all local regulations and facilitating a smooth application process.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default EatingHouseLicense;