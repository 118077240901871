import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn4Navbar = ({ height }) => {

  // IT-Return 4 grid items
  const items = [
    { title: 'Presumptive Income Tax Scheme', description: 'Eligibility and benefits of filing under the Presumptive Income Tax Scheme for small businesses and professionals.' },
    { title: 'Income from Business or Profession', description: 'Taxation on income earned from business or professional services, including self-employment.' },
    { title: 'Tax Deductions and Exemptions', description: 'Available deductions and exemptions under IT-Return 4 for businesses, including Section 44AD, 44ADA, and 44AE.' },
    { title: 'Simplified Return Filing Process', description: 'Streamlined process for eligible taxpayers to file their return under the presumptive taxation scheme.' },
    { title: 'Compliance and Reporting', description: 'Ensure compliance with Indian tax laws and accurate reporting of income from businesses and professions.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Easy Filing Process - Simplified return filing under the presumptive income scheme, reducing the paperwork and complexity.",
    "Low Taxable Income - Applicable to small businesses and professionals, leading to reduced taxable income based on turnover.",
    "Tax Deductions - Ability to claim deductions under relevant sections such as 44AD, 44ADA, and 44AE.",
    "Less Audits - Reduced likelihood of scrutiny or audits under presumptive taxation schemes.",
    "No Detailed Books Required - Small businesses and professionals are exempt from maintaining detailed books of accounts.",
  ];

  const disadvantages = [
    "Limited to Small Businesses - Only applicable for small businesses or professionals with a specified turnover limit.",
    "No Additional Deductions - Additional deductions like home loans or savings investments may not be claimed under presumptive income schemes.",
    "Turnover Restrictions - Turnover must fall within prescribed limits to qualify for presumptive taxation.",
  ];

  const documents = [
    {
      title: "Income Proof",
      description: "Documents such as invoices, bank statements, and payment receipts showing income earned from business or professional services."
    },
    {
      title: "Previous Tax Returns",
      description: "Copies of previous years' tax returns for continuity and reference during filing."
    },
    {
      title: "Business Expenses",
      description: "Proof of expenses related to business or professional services, including rent, salaries, utilities, etc."
    },
    {
      title: "GST Returns (if applicable)",
      description: "GST return filings for businesses registered under the Goods and Services Tax regime."
    },
    {
      title: "Turnover Records",
      description: "Details of turnover, sales, or revenue generated from business or profession."
    }
  ];

  const steps = [
    {
      title: "Check Eligibility",
      description: "Ensure your business or profession meets the eligibility criteria for filing under IT-Return 4."
    },
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents such as income proof, turnover details, and previous tax returns."
    },
    {
      title: "Calculate Presumptive Income",
      description: "Determine your presumptive income based on turnover under Section 44AD, 44ADA, or 44AE."
    },
    {
      title: "File IT-Return 4",
      description: "Prepare and submit IT-Return 4, ensuring all income, deductions, and exemptions are correctly reported."
    },
    {
      title: "Comply with Tax Regulations",
      description: "Ensure full compliance with tax regulations, including GST filings (if applicable) and maintaining basic financial records."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 4: Simplified Tax Filing for Small Businesses
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              IT-Return 4 is designed for small businesses and professionals, offering a simplified process for filing income tax returns under presumptive income schemes.
            </p>

            <p>Ensure compliance with Indian tax regulations while benefiting from reduced tax liabilities through our IT-Return 4 services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IT-Return 4 Filing Services</h6>
          <p className='text-center mt-4'>Our IT-Return 4 services simplify tax filing for small businesses and professionals under the presumptive income scheme, ensuring compliance with Indian tax laws.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 4?</h6>

            <p className='mb-4'>IT-Return 4 is a simplified tax return form designed for small businesses, professionals, and self-employed individuals who opt for the presumptive taxation scheme. This scheme allows for easier tax filing and reduced compliance requirements.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for IT-Return 4</h6>
            <p className='mb-4'>To file IT-Return 4, the following eligibility criteria must be met:
              <ul>
                <li><strong>Turnover Limit -</strong> Your business or profession must have a turnover not exceeding the prescribed limit (e.g., ₹2 crore for businesses under Section 44AD).</li>

                <li><strong>Presumptive Income Scheme -</strong> The presumptive income scheme is applicable to eligible businesses and professions under Sections 44AD, 44ADA, and 44AE.</li>

                <li><strong>No Detailed Bookkeeping -</strong> Taxpayers filing IT-Return 4 are not required to maintain detailed books of accounts.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of IT-Return 4</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of IT-Return 4</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for IT-Return 4</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>IT-Return 4 Filing Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ITReturn4Navbar;