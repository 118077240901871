import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import FCRA from './servicesIMG/FCRA.webp'
import ScrollToTopButton from './ScrollTop';

const FCRARegistration = ({ height }) => {

  // FCRA Registration grid items
  const items = [
    { title: 'What is FCRA Registration?', description: 'FCRA stands for Foreign Contribution Regulation Act. It is a law enacted by the Government of India to regulate foreign donations and ensure their proper utilization.' },
    { title: 'Who Needs FCRA?', description: 'Non-profit organizations, charitable trusts, and NGOs that wish to receive foreign donations must register under FCRA.' },
    { title: 'Key Features of FCRA', description: 'FCRA helps in the transparent receipt and usage of foreign contributions, ensuring national security and accountability.' },
    { title: 'Eligibility for FCRA', description: 'Organizations must be registered for at least 3 years and have spent a minimum amount on charitable activities to be eligible for FCRA registration.' },
    { title: 'Legal Governance', description: 'FCRA is governed by the Ministry of Home Affairs, which monitors the flow and utilization of foreign contributions in India.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Access to Foreign Donations - FCRA registration allows organizations to legally accept foreign donations.",
    "Increased Credibility - Being registered under FCRA adds credibility and trustworthiness to the organization.",
    "Tax Benefits - Foreign donations received under FCRA are tax-exempt under Section 80G.",
    "International Reach - FCRA enables organizations to engage with global donors, expanding their reach and impact."
  ];

  const disadvantages = [
    "Strict Compliance - Organizations must comply with numerous regulations and reporting requirements.",
    "Periodic Renewal - FCRA registration must be renewed every 5 years, with stringent checks.",
    "Penalties for Non-compliance - Failure to comply with FCRA regulations can lead to heavy penalties, including cancellation of registration.",
    "Restrictions on Political Activities - Organizations registered under FCRA are restricted from engaging in political activities."
  ];

  const documents = [
    {
      title: "Registration Certificate",
      description: "The organization must submit its registration certificate or trust deed during the FCRA application process."
    },
    {
      title: "Past Financial Records",
      description: "The financial records of the past 3 years showing the organization’s expenditures must be submitted."
    },
    {
      title: "List of Directors/Trustees",
      description: "A list of all directors or trustees involved in the organization must be provided."
    },
    {
      title: "Foreign Donor Agreement",
      description: "Details of any agreements or intentions to receive foreign donations must be shared with the Ministry."
    },
    {
      title: "Activity Reports",
      description: "The organization must submit detailed reports of its past activities and the purpose of receiving foreign contributions."
    }
  ];

  const steps = [
    {
      title: "Apply Online",
      description: "The organization must apply online via the FCRA portal and submit all required documents."
    },
    {
      title: "Verification",
      description: "The Ministry of Home Affairs will verify the documents and the activities of the organization."
    },
    {
      title: "Approval from MHA",
      description: "Once the verification process is complete, the Ministry of Home Affairs provides approval for FCRA registration."
    },
    {
      title: "Issue of Registration Certificate",
      description: "After approval, the organization is issued an FCRA registration certificate, allowing it to receive foreign contributions."
    },
    {
      title: "Regular Compliance",
      description: "The organization must maintain compliance with FCRA rules and file annual returns."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              FCRA Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Enable your organization to legally receive foreign contributions by registering under FCRA.
            </p>

            <p>Ensure that your NGO or non-profit can access foreign funding by complying with the FCRA guidelines.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={FCRA} alt='FCRA'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>FCRA Registration</h6>
          <p className='text-center mt-4'>Legalize the acceptance of foreign contributions by registering under the Foreign Contribution Regulation Act (FCRA).</p>
        </div>

          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is FCRA Registration?</h6>

            <p className='mb-4'>FCRA registration allows organizations to receive foreign donations for charitable, religious, or educational purposes under strict regulations.</p>
          </div>

          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for FCRA Registration</h6>
            <p className='mb-4'>Non-profits with at least three years of operational history and a minimum amount spent on charitable activities are eligible for FCRA registration.</p>
          </div>

          <div id="features" className='w-full  mt-10 px-10  pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of FCRA Registration</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of FCRA Registration</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className='advantages-section mb-10' style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for FCRA Registration</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((document, index) => (
                <Card key={index} title={document.title} description={document.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Register for FCRA</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default FCRARegistration;