import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const InternationalTrademarkRegistration = ({ height }) => {

  // International Trademark Registration grid items
  const items = [
    { title: 'Trademark Search', description: 'Conduct a comprehensive search to ensure your trademark does not conflict with existing marks.' },
    { title: 'Application Filing', description: 'File your trademark application with the appropriate international authorities.' },
    { title: 'Examination Process', description: 'Navigate through the examination process to address any objections or requirements from the trademark office.' },
    { title: 'Opposition Period', description: 'Manage any opposition proceedings that may arise from third parties challenging your trademark.' },
    { title: 'Registration and Protection', description: 'Once approved, manage your trademark registration to ensure ongoing protection and enforcement.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Global Protection - Provides trademark protection across multiple countries with a single application.",
    "Enhanced Brand Security - Safeguards your brand from infringement and misuse on an international scale.",
    "Streamlined Process - Simplifies the process of applying for trademark protection in various jurisdictions.",
    "Market Expansion - Facilitates the entry into new international markets with secured brand identity.",
    "Legal Recourse - Offers legal recourse in case of trademark disputes or infringements globally.",
  ];

  const disadvantages = [
    "High Costs - Can be expensive due to filing fees, legal fees, and maintenance costs in multiple jurisdictions.",
    "Complexity - Involves a complex application process and compliance with varying national trademark laws.",
    "Maintenance Requirements - Requires ongoing monitoring and renewal of registrations in each country.",
    "Potential for Conflicts - Risk of conflicts with local trademarks or objections during the examination process.",
  ];

  const documents = [
    {
      title: "Trademark Search Report",
      description: "A report confirming that your trademark is available and does not conflict with existing marks."
    },
    {
      title: "Application Forms",
      description: "Completed application forms required for filing with international trademark authorities."
    },
    {
      title: "Proof of Use",
      description: "Evidence showing how you are using the trademark in commerce, if applicable."
    },
    {
      title: "Power of Attorney",
      description: "A document authorizing a representative or attorney to file the trademark application on your behalf."
    },
    {
      title: "Business Registration Documents",
      description: "Documents proving the legitimacy of your business entity for trademark registration purposes."
    }
  ];

  const steps = [
    {
      title: "Conduct a Trademark Search",
      description: "Start by conducting a thorough trademark search to ensure your desired mark is available for registration."
    },
    {
      title: "Prepare Application Documents",
      description: "Prepare and gather all necessary documents and information for the trademark application."
    },
    {
      title: "File the Application",
      description: "Submit the trademark application to the appropriate international trademark office or system."
    },
    {
      title: "Monitor the Examination Process",
      description: "Track the progress of your application and respond to any objections or requirements from the trademark office."
    },
    {
      title: "Manage Registration and Renewals",
      description: "Once registered, manage your trademark to ensure its protection, including timely renewals and enforcement actions."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              International Trademark Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              International Trademark Registration provides a streamlined approach to protecting your brand across multiple countries. Our services help you secure global trademark protection and manage your intellectual property effectively.
            </p>

            <p>Safeguard your brand's identity on an international scale with our expert trademark registration services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>International Trademark Registration Services</h6>
          <p className='text-center mt-4'>Our International Trademark Registration services offer a comprehensive solution for securing trademark protection across multiple countries. Benefit from our expertise in navigating complex international trademark laws and safeguarding your brand globally.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is International Trademark Registration?</h6>

            <p className='mb-4'>International Trademark Registration allows you to protect your trademark in multiple countries through a single application. This service streamlines the process of securing brand protection across various jurisdictions, ensuring that your intellectual property is safeguarded globally.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for International Trademark Registration</h6>
            <p className='mb-4'>To be eligible for International Trademark Registration, you should meet the following criteria:
              <ul>
                <li><strong>Existing Trademark -</strong> You must have an existing trademark that you wish to protect internationally.</li>

                <li><strong>Complete Application -</strong> Ensure all necessary documentation and information for the international application is prepared.</li>

                <li><strong>Global Presence -</strong> Consider if your business operates or plans to operate in multiple countries where trademark protection is desired.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of International Trademark Registration</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of International Trademark Registration</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for International Trademark Registration</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>International Trademark Registration Filing Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default InternationalTrademarkRegistration;