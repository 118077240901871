import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import taxpaln from './servicesIMG/Taxplan.webp'
const BusinessPlanPreparation = ({ height }) => {

  // Business Plan Preparation grid items
  const items = [
    { title: 'Market Research', description: 'Conduct comprehensive research to understand market trends and opportunities.' },
    { title: 'Financial Projections', description: 'Develop detailed financial forecasts, including revenue, expenses, and profit margins.' },
    { title: 'Business Model', description: 'Define your business model and strategy for achieving your goals.' },
    { title: 'Executive Summary', description: 'Create a compelling executive summary that highlights the key aspects of your business plan.' },
    { title: 'Implementation Plan', description: 'Outline the steps and timeline for executing your business plan successfully.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Strategic Planning - Helps in setting clear goals and strategies for business growth.",
    "Investor Attraction - A well-prepared business plan can attract investors and secure funding.",
    "Risk Management - Identifies potential risks and outlines strategies to mitigate them.",
    "Organized Structure - Provides a structured approach to managing and operating the business.",
    "Goal Setting - Establishes clear objectives and benchmarks for measuring success.",
  ];

  const disadvantages = [
    "Time-Consuming - Preparing a comprehensive business plan can be time-consuming.",
    "Complexity - May require significant effort to gather and analyze data.",
    "Possibility of Over-Optimism - Projections and assumptions may be overly optimistic.",
  ];

  const documents = [
    {
      title: "Market Research Report",
      description: "Detailed report on market trends, customer needs, and competitive analysis."
    },
    {
      title: "Financial Projections",
      description: "Forecasts including profit and loss, cash flow, and balance sheet."
    },
    {
      title: "Business Model Canvas",
      description: "Outline of your business model, including value proposition, key activities, and revenue streams."
    },
    {
      title: "Executive Summary",
      description: "Summary of the key elements of your business plan."
    },
    {
      title: "Implementation Plan",
      description: "Detailed plan for executing the business strategy and achieving goals."
    }
  ];

  const steps = [
    {
      title: "Conduct Market Research",
      description: "Gather and analyze data on the market, competitors, and target audience."
    },
    {
      title: "Develop Financial Projections",
      description: "Create detailed financial forecasts, including revenue, expenses, and profitability."
    },
    {
      title: "Define Business Model",
      description: "Outline your business model and strategy for achieving success."
    },
    {
      title: "Prepare Executive Summary",
      description: "Draft a concise summary that highlights the key points of your business plan."
    },
    {
      title: "Create Implementation Plan",
      description: "Develop a step-by-step plan for executing your business strategy."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Business Plan Preparation
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A comprehensive business plan is essential for guiding your business towards success. Our service assists in preparing a detailed business plan that covers all critical aspects, including market research, financial projections, and implementation strategies.
            </p>

            <p>Get expert help in creating a business plan that sets you up for success.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
               <img src={taxpaln} alt='tax-plan in mins'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Business Plan Preparation</h6>
          <p className='text-center mt-4'>A business plan outlines your business goals, strategies, and financial projections. It serves as a roadmap for achieving success and is crucial for attracting investors and managing business operations effectively.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Business Plan Preparation?</h6>

            <p className='mb-4'>Business Plan Preparation involves creating a detailed document that outlines your business goals, strategies, and financial projections. It provides a roadmap for running your business and helps in attracting investors and managing operations effectively.</p>
          </div>

          <div id="application" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Business Plan Preparation</h6>
            <p className='mb-4'>To prepare a business plan, follow these steps:

              <ul>
                <li><strong>Conduct Market Research -</strong> Gather data on the market, competitors, and target audience.</li>

                <li><strong>Develop Financial Projections -</strong> Create forecasts for revenue, expenses, and profitability.</li>

                <li><strong>Define Business Model -</strong> Outline your business model and strategy.</li>

                <li><strong>Prepare Executive Summary -</strong> Draft a summary highlighting key elements of the business plan.</li>

                <li><strong>Create Implementation Plan -</strong> Develop a plan for executing your business strategy.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Business Plan Preparation Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our Business Plan Preparation Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our Business Plan Preparation Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for Business Plan Preparation</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="process" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete Business Plan Preparation</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default BusinessPlanPreparation;