import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ImportExportc from './servicesIMG/IEC.webp'

const IECNavbar = ({ height }) => {

  // IEC service grid items
  const items = [
    { title: 'Import Authorization', description: 'Enables businesses to import goods and services into the country.' },
    { title: 'Export Authorization', description: 'Facilitates the export of goods and services from the country.' },
    { title: 'Simplified Process', description: 'Streamlined application process for obtaining IEC with minimal documentation.' },
    { title: 'Online Registration', description: 'Apply for IEC registration online through the DGFT portal.' },
    { title: 'Global Trade Opportunities', description: 'Unlocks opportunities for international trade and global market access.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Authorization - Grants the legal right to import and export goods and services.",
    "No Renewal Required - Once obtained, the IEC does not require renewal.",
    "Online Application - Simple and efficient online application process.",
    "Trade Benefits - Access to various trade benefits and incentives from the government.",
  ];

  const disadvantages = [
    "Compliance Requirements - Must adhere to various regulatory requirements and compliance norms.",
    "Documentation - Requires accurate documentation and adherence to trade regulations.",
    "Operational Costs - Potential costs related to international trade and compliance.",
  ];

  const documents = [
    {
      title: "PAN Card",
      description: "A valid PAN card of the business or its owner is required for registration."
    },
    {
      title: "Business Address Proof",
      description: "Documents proving the business address, such as utility bills or rental agreements."
    },
    {
      title: "Bank Account Details",
      description: "Details of the business’s bank account for verification purposes."
    },
    {
      title: "Business Registration Certificate",
      description: "Proof of the business's legal registration, such as a certificate of incorporation."
    },
    {
      title: "Identity Proof",
      description: "Identity proof of the business owner or authorized signatory."
    }
  ];

  const steps = [
    {
      title: "Obtain PAN Card",
      description: "Ensure that you have a valid PAN card for the business or owner."
    },
    {
      title: "Prepare Documents",
      description: "Gather all necessary documents required for IEC registration."
    },
    {
      title: "Apply Online",
      description: "Submit the application for IEC registration online through the DGFT portal."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with required documents for verification."
    },
    {
      title: "Receive IEC Code",
      description: "After successful verification, receive the Import Export Code (IEC) issued by DGFT."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Import Export Code (IEC) Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              IEC Registration is essential for businesses involved in international trade, providing the legal authorization to import and export goods and services.
            </p>

            <p>Thousands of businesses have successfully obtained their IEC with us.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={ImportExportc} alt='Import Export Code'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IEC Registration Made Simple</h6>
          <p className='text-center mt-4'>Obtaining an Import Export Code (IEC) is straightforward and crucial for international trade. We assist you in every step to ensure a hassle-free registration process.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is IEC?</h6>

            <p className='mb-4'>The Import Export Code (IEC) is a unique code issued by the Directorate General of Foreign Trade (DGFT) that allows businesses to engage in international trade. It is essential for exporting and importing goods and services.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for IEC Registration</h6>
            <p className='mb-4'>To be eligible for IEC registration, the business must meet the following criteria:

              <ul>
                <li><strong>Business Type -</strong> The applicant must be a registered business entity involved in international trade.</li>

                <li><strong>PAN Card -</strong> A valid PAN card of the business or its owner is required.</li>

                <li><strong>Business Address Proof -</strong> Provide proof of the business address, such as utility bills or rental agreements.</li>

                <li><strong>Bank Account Details -</strong> Details of the business’s bank account for verification purposes.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of IEC Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of IEC Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of IEC Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for IEC Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Register for IEC</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>


    </>
  );
};

export default IECNavbar;