import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn5Navbar = ({ height }) => {

  // IT-Return 5 grid items
  const items = [
    { title: 'Income from Salary', description: 'Taxation details for individuals earning income through salary, including deductions and exemptions.' },
    { title: 'Income from House Property', description: 'Guidance on tax treatment of income earned from house property, including deductions for interest on home loans.' },
    { title: 'Capital Gains Tax', description: 'Information on taxation of capital gains from the sale of assets such as stocks, real estate, and other investments.' },
    { title: 'Tax Reliefs and Benefits', description: 'Overview of available tax reliefs and benefits under IT-Return 5, including exemptions and deductions.' },
    { title: 'Income from Other Sources', description: 'Taxation on income from other sources such as interest income, royalties, and dividends.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Coverage - Includes various sources of income like salary, house property, and capital gains.",
    "Detailed Deductions - Access to detailed deductions and exemptions to reduce taxable income.",
    "Tax Reliefs - Availability of tax reliefs and benefits to further reduce tax liabilities.",
    "Clear Guidelines - Well-defined rules for reporting different types of income.",
    "Ease of Filing - Simplified filing process with predefined sections for different income sources.",
  ];

  const disadvantages = [
    "Complexities in Reporting - Reporting different types of income may be complex for some taxpayers.",
    "Potential for Errors - Increased likelihood of errors if all sources of income and deductions are not accurately reported.",
    "Detailed Documentation Required - Extensive documentation might be required to support various claims and deductions.",
  ];

  const documents = [
    {
      title: "Salary Slips",
      description: "Copies of salary slips to verify income earned through employment."
    },
    {
      title: "House Property Documents",
      description: "Documents related to house property, including rent receipts and home loan statements."
    },
    {
      title: "Capital Gains Statements",
      description: "Statements detailing capital gains from the sale of assets such as stocks and real estate."
    },
    {
      title: "Interest Income Proof",
      description: "Proof of interest income earned from savings accounts, fixed deposits, or other investments."
    },
    {
      title: "Other Income Records",
      description: "Records of other sources of income such as dividends, royalties, or any other non-salary income."
    }
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents related to salary, house property, capital gains, and other sources of income."
    },
    {
      title: "Calculate Income and Deductions",
      description: "Determine the total income and calculate applicable deductions and exemptions."
    },
    {
      title: "Prepare IT-Return 5",
      description: "Fill out the IT-Return 5 form with accurate details of income, deductions, and exemptions."
    },
    {
      title: "Submit Return",
      description: "File the completed IT-Return 5 form electronically or manually, as per the guidelines."
    },
    {
      title: "Review and Comply",
      description: "Review the filed return for accuracy and ensure compliance with all tax regulations."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 5: Comprehensive Income Tax Filing
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              IT-Return 5 covers income from various sources including salary, house property, and capital gains. It offers detailed guidelines to ensure accurate reporting and maximization of tax reliefs and deductions.
            </p>

            <p>Streamline your tax filing process with our expert assistance for IT-Return 5, ensuring compliance and optimized tax benefits.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IT-Return 5 Filing Services</h6>
          <p className='text-center mt-4'>Our IT-Return 5 services provide comprehensive assistance for filing income tax returns covering salary, house property, capital gains, and other income sources. Ensure accurate reporting and benefit from available tax reliefs.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 5?</h6>

            <p className='mb-4'>IT-Return 5 is designed for individuals with multiple sources of income including salary, house property, and capital gains. It simplifies the reporting process and ensures all applicable deductions and exemptions are utilized.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for IT-Return 5</h6>
            <p className='mb-4'>To file IT-Return 5, you need to fulfill the following criteria:
              <ul>
                <li><strong>Income Sources -</strong> The return is applicable if you have income from salary, house property, capital gains, or other sources.</li>

                <li><strong>Documented Proof -</strong> Ensure you have all required documents for each income source to substantiate claims.</li>

                <li><strong>Tax Reliefs -</strong> Make sure you are aware of the available tax reliefs and benefits for accurate filing.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of IT-Return 5</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of IT-Return 5</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for IT-Return 5</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>IT-Return 5 Filing Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ITReturn5Navbar;