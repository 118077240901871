import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import annualreturn from './servicesIMG/AnnualReturn.webp'

const AnnualReturns = ({ height }) => {

  // Annual Returns grid items
  const items = [
    { title: 'Regulatory Compliance', description: 'Ensure compliance with annual filing requirements to avoid penalties.' },
    { title: 'Timely Submissions', description: 'Manage and track the submission deadlines efficiently.' },
    { title: 'Financial Reporting', description: 'Accurate reporting of financial performance and business activities.' },
    { title: 'Documentation Management', description: 'Organize and maintain all required documentation for filings.' },
    { title: 'Expert Assistance', description: 'Get professional help to prepare and submit annual returns accurately.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Regulatory Compliance - Ensures that your business meets all legal filing requirements and avoids fines.",
    "Timely Submissions - Helps manage and track submission deadlines to ensure timely filings.",
    "Financial Reporting - Provides accurate reporting of your business’s financial status and activities.",
    "Documentation Management - Facilitates organization and maintenance of all necessary documents for filings.",
    "Expert Assistance - Professional help available to ensure accuracy and compliance in preparing annual returns."
  ];

  const disadvantages = [
    "Complexity - The process can be complex and time-consuming, requiring careful attention to detail.",
    "Costs - There may be costs associated with preparing and filing annual returns, including professional fees.",
    "Risk of Penalties - Failure to file on time or inaccuracies can lead to legal and financial penalties.",
    "Documentation Requirements - Maintaining extensive documentation can be burdensome.",
    "Ongoing Obligations - Regular updates and compliance measures are necessary throughout the year."
  ];

  const documents = [
    {
      title: "Financial Statements",
      description: "Detailed financial statements to be included in the annual returns."
    },
    {
      title: "Board Resolutions",
      description: "Resolutions passed by the board of directors for annual filings."
    },
    {
      title: "Audit Reports",
      description: "Reports from auditors confirming the accuracy of financial statements."
    },
    {
      title: "Tax Returns",
      description: "Completed tax returns that need to be submitted along with the annual returns."
    },
    {
      title: "Company Records",
      description: "Up-to-date records of company activities and resolutions."
    }
  ];

  const steps = [
    {
      title: "Review Requirements",
      description: "Understand the annual return requirements specific to your business type and jurisdiction."
    },
    {
      title: "Gather Documents",
      description: "Collect all necessary documents and financial statements required for submission."
    },
    {
      title: "Prepare Returns",
      description: "Prepare the annual returns based on the gathered information and ensure accuracy."
    },
    {
      title: "File Returns",
      description: "Submit the prepared annual returns to the relevant authorities before the deadline."
    },
    {
      title: "Confirm Submission",
      description: "Verify the submission and retain proof of filing for future reference."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Annual Returns Filing
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Simplify your annual returns filing with our expert services. Ensure compliance, manage deadlines, and get professional assistance for accurate submissions.
            </p>

            <p>Our annual returns filing services help you meet regulatory requirements, maintain proper documentation, and avoid penalties. We ensure your filings are completed accurately and on time.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '100%',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
               <img src={annualreturn} alt='Annual Returns'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>

        <div id="overview" className="LLPRegis  w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Annual Returns Made Easy</h6>
          <p className='text-center mt-4'>Our annual returns services simplify the process, ensuring compliance with regulations and accurate financial reporting. Let us handle the complexities while you focus on your business.</p>
        </div>

          <div className='w-auto flex-row flex-wrap' >
            <h6 className='text-blue-500 text-lg text-center font-semibold'>What are Annual Returns?</h6>

            <p className='text-center '>Annual Returns are documents that companies are required to file yearly to report their financial status and other relevant information to regulatory authorities. They ensure compliance with legal requirements and provide transparency.</p>
          </div>

          <div id="eligibility" >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To file annual returns, your business needs:

              <ul>
                <li><strong>Company Registration -</strong> A registered company entity is required for filing annual returns.</li>

                <li><strong>Financial Statements -</strong> Detailed financial statements for the reporting period.</li>

                <li><strong>Board Resolutions -</strong> Resolutions passed by the board related to annual filings.</li>

                <li><strong>Audit Reports -</strong> Reports from auditors confirming the accuracy of financial statements.</li>

                <li><strong>Tax Returns -</strong> Completed tax returns for the fiscal year.</li>
              </ul>
            </p>
          </div>

          <div id="features">
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Annual Returns</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" >
            <div className='AdvantagesLLP'>
              <h2>Advantages of Annual Returns</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            <div className='DisadvantagesLLP '>
              <h2>Disadvantages of Annual Returns</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
              </div>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 '>
            <h2>Documents Required for Annual Returns</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 '>
            <h2>Steps to File Annual Returns</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose flex-row flex-wrap'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Annual Returns?</h6>
            <p>We offer comprehensive support for filing annual returns, ensuring compliance with regulatory requirements. Our team helps you gather the necessary documents, prepares accurate returns, and handles the submission process, saving you time and avoiding potential penalties.</p>
          </div>
      </div>

    </>
  );
};

export default AnnualReturns;