import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const TrustRegistration = ({ height }) => {

  // Trust Registration grid items
  const items = [
    { title: 'What is Trust Registration?', description: 'Trust registration in India allows individuals to establish a legal entity for charitable, educational, or religious purposes.' },
    { title: 'Who Can Form a Trust?', description: 'Any group of individuals or a person can form a trust to carry out non-commercial objectives like helping society.' },
    { title: 'Key Features of a Trust', description: 'A registered trust gains legal status, enabling it to manage assets and conduct lawful activities.' },
    { title: 'Types of Trusts', description: 'Trusts can be public or private, with public trusts aimed at benefiting the general public, while private trusts are for specific individuals.' },
    { title: 'Legal Governance', description: 'Trusts are governed by state-specific laws like the Indian Trusts Act, 1882, or the state’s Public Trusts Act.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Recognition - Registered trusts have legal standing and can engage in various charitable activities with full legal backing.",
    "Tax Benefits - Trusts can apply for exemptions under Sections 11 and 12 of the Income Tax Act for tax benefits.",
    "Asset Management - Trusts can hold and manage property for the benefit of the public or specific beneficiaries.",
    "Public Confidence - Trusts provide transparency and accountability, increasing trust from donors and authorities."
  ];

  const disadvantages = [
    "Complex Compliance - Registered trusts must adhere to various legal and financial compliances, including filing annual returns.",
    "Limited Control - Trustees are legally bound to the objectives of the trust and cannot diverge from its intended purpose.",
    "Inflexibility - Trusts have rigid structures and are difficult to amend once registered.",
    "Dependency on Donations - Trusts often rely heavily on donations or grants, limiting operational flexibility."
  ];

  const documents = [
    {
      title: "Trust Deed",
      description: "A legal document that defines the objectives, beneficiaries, and trustees of the trust."
    },
    {
      title: "ID and Address Proof of Settlor",
      description: "The settlor is the person who creates the trust. Their ID and address proof must be submitted."
    },
    {
      title: "Trustee Details",
      description: "The names, addresses, and identification documents of all trustees must be provided."
    },
    {
      title: "Registered Office Address",
      description: "Proof of the registered office address where the trust will be headquartered is required."
    },
    {
      title: "Trust Registration Fee",
      description: "The registration fee needs to be paid as per state regulations."
    }
  ];

  const steps = [
    {
      title: "Prepare Trust Deed",
      description: "Draft the trust deed, clearly outlining the objectives, trustees, and management of the trust."
    },
    {
      title: "Submit Documents",
      description: "Submit the trust deed along with the required documents to the Registrar of Trusts in your state."
    },
    {
      title: "Verification by Registrar",
      description: "The Registrar reviews the documents and may conduct interviews with trustees to ensure compliance."
    },
    {
      title: "Issue Certificate of Registration",
      description: "Once the verification is complete, the trust is registered and the registration certificate is issued."
    },
    {
      title: "Compliance and Maintenance",
      description: "After registration, the trust must comply with annual filings and legal obligations."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px '}}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trust Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Register a trust to operate for charitable, educational, or religious purposes and gain legal standing.
            </p>

            <p>Ensure your trust complies with legal norms and can serve its beneficiaries effectively.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-full ">
          <h6 className='text-2xl text-center font-semibold'>Trust Registration</h6>
          <p className='text-center mt-4'>A legal process to register a trust for charitable or religious purposes under the Indian Trusts Act, 1882.</p>
        </div>


          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trust Registration?</h6>

            <p className='mb-4'>Trust registration allows individuals or groups to form a non-profit entity to serve charitable or religious objectives.</p>
          </div>

          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Trust Registration</h6>
            <p className='mb-4'>To register a trust, a settlor must appoint trustees and clearly define the trust’s objectives and beneficiaries.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Trust Registration</h6>
            <div className="card-grid mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Trust Registration</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className='advantages-section mb-10' style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Trust Registration</h6>
            <div className="card-grid mt-10">
              {documents.map((document, index) => (
                <Card key={index} title={document.title} description={document.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Register a Trust</h6>
            <div className="card-grid mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>

      </div>

      <ScrollToTopButton />
    </>
  );
};

export default TrustRegistration;