import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ChangeAuthCapital = ({ height }) => {

  // Change Authorized Capital grid items
  const items = [
    { title: 'Increased Flexibility', description: 'Adjust your company’s authorized capital to accommodate growth or new investments.' },
    { title: 'Improved Financial Health', description: 'Enhance your company’s financial stability by increasing its capital base.' },
    { title: 'Investor Confidence', description: 'Boost investor confidence by demonstrating financial strength and potential.' },
    { title: 'Legal Compliance', description: 'Ensure compliance with legal requirements for authorized capital changes.' },
    { title: 'Streamlined Process', description: 'Benefit from a streamlined process for updating your company’s authorized capital efficiently.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Enhanced Flexibility - Allows you to adjust your company’s capital to match its financial needs and business goals.",
    "Stronger Financial Position - Increasing authorized capital can improve your company’s financial health and stability.",
    "Investor Appeal - A higher authorized capital may attract more investors and improve their confidence in your business.",
    "Regulatory Compliance - Helps ensure that your company remains compliant with regulatory requirements related to capital structure.",
    "Efficient Process - A streamlined process ensures that changes are made quickly and with minimal disruption to business operations."
  ];

  const disadvantages = [
    "Increased Documentation - Changing authorized capital may require additional documentation and regulatory filings.",
    "Potential Costs - There might be costs associated with legal and administrative aspects of changing authorized capital.",
    "Impact on Shareholders - Changes in authorized capital could affect existing shareholders, particularly if new shares are issued.",
    "Complexity - The process can be complex and may require professional assistance to ensure all legal requirements are met."
  ];

  const documents = [
    {
      title: "Board Resolution",
      description: "A resolution passed by the board of directors approving the change in authorized capital."
    },
    {
      title: "Shareholders’ Approval",
      description: "Approval from shareholders, if required, for the change in authorized capital."
    },
    {
      title: "Updated Memorandum of Association (MOA)",
      description: "Revised MOA reflecting the new authorized capital."
    },
    {
      title: "Compliance Certificate",
      description: "Certificate confirming compliance with relevant regulatory requirements for capital change."
    }
  ];

  const steps = [
    {
      title: "Board Meeting",
      description: "Hold a board meeting to approve the decision to change the authorized capital."
    },
    {
      title: "Shareholders’ Meeting",
      description: "Obtain approval from shareholders if necessary, for the proposed change in authorized capital."
    },
    {
      title: "Amend MOA",
      description: "Update the Memorandum of Association (MOA) to reflect the new authorized capital."
    },
    {
      title: "File with Authorities",
      description: "Submit the necessary documents and forms to regulatory authorities for approval."
    },
    {
      title: "Receive Confirmation",
      description: "Obtain confirmation from authorities that the change in authorized capital has been registered."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Change Authorized Capital of Your Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Adjusting your company’s authorized capital is essential for accommodating growth, attracting investors, and improving financial stability. Our streamlined process makes it easy to update your capital efficiently and effectively.
            </p>

            <p>Optimize your company’s financial health and investor appeal with our expert assistance in changing authorized capital.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Change Your Company’s Authorized Capital with Ease</h6>
          <p className='text-center mt-4'>Modifying your company’s authorized capital can provide flexibility for growth and attract potential investors. Our efficient process ensures a smooth transition while maintaining compliance with all legal requirements.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Changing Authorized Capital?</h6>

            <p className='mb-4'>Changing authorized capital involves increasing or decreasing the amount of capital a company is authorized to raise through the issuance of shares. This adjustment can help in accommodating business expansion or improving financial positioning.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To change the authorized capital of your company, you need:

              <ul>
                <li><strong>Board Resolution -</strong> A resolution from the board of directors approving the change in authorized capital.</li>

                <li><strong>Shareholders’ Approval -</strong> Approval from shareholders if necessary.</li>

                <li><strong>Updated MOA -</strong> Revised Memorandum of Association (MOA) reflecting the new authorized capital.</li>

                <li><strong>Compliance Certificate -</strong> Certificate confirming adherence to regulatory requirements.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Changing Authorized Capital</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Changing Authorized Capital</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Changing Authorized Capital</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Changing Authorized Capital</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Change Authorized Capital</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Changing Authorized Capital?</h6>
            <p>We offer a comprehensive and efficient process for changing your company’s authorized capital. Our experienced team ensures all legal requirements are met, and we handle the process with minimal disruption to your business.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ChangeAuthCapital;