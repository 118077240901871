import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard'; // Assuming Card component is used for feature display
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
// import Propfirm from './servicesIMG/Propfirm.webp'

const ProprietorshipToPrivateLimitedNavbar = ({ height }) => {

  // Features, advantages, and process items
  const items = [
    { title: 'Limited Liability', description: 'Shareholders’ liability is limited to their shares in the company.' },
    { title: 'Separate Legal Entity', description: 'The company is a separate legal entity from its owners.' },
    { title: 'Enhanced Credibility', description: 'Greater credibility and trust among clients and partners.' },
    { title: 'Ease of Raising Capital', description: 'Easier to raise capital through the sale of shares.' },
    { title: 'Structured Management', description: 'Structured management with defined roles and responsibilities.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Limited Liability - Shareholders’ liability is limited to their shares in the company.",
    "Separate Legal Entity - The company is a separate legal entity from its owners.",
    "Enhanced Credibility - Greater credibility and trust among clients and partners.",
    "Ease of Raising Capital - Easier to raise capital through the sale of shares.",
    "Structured Management - Structured management with defined roles and responsibilities."
  ];

  const disadvantages = [
    "Increased Compliance - More regulatory compliance and reporting requirements.",
    "Higher Costs - Higher costs associated with incorporation and ongoing compliance.",
    "Complex Structure - More complex structure compared to a proprietorship firm.",
    "Formalities - Requires adherence to formalities for meetings, resolutions, and documentation."
  ];

  const documents = [
    {
      title: "Proprietorship Registration Certificate",
      description: "Copy of the registration certificate of the proprietorship firm."
    },
    {
      title: "Proof of Address",
      description: "Proof of address of the registered office of the new company."
    },
    {
      title: "Identity Proof of Proprietor",
      description: "Identity proof of the proprietor and proposed directors."
    },
    {
      title: "PAN Card",
      description: "Permanent Account Number (PAN) of the proprietor and proposed directors."
    },
    {
      title: "Shareholders Agreement",
      description: "Agreement detailing the rights and duties of shareholders."
    },
    {
      title: "Director’s Consent",
      description: "Written consent from individuals who will be appointed as directors."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificates (DSC)",
      description: "Acquire DSCs for all the proposed directors of the company."
    },
    {
      title: "Obtain Director Identification Number (DIN)",
      description: "Apply for DIN for all the proposed directors."
    },
    {
      title: "Draft Company Documents",
      description: "Prepare the Memorandum of Association (MOA) and Articles of Association (AOA)."
    },
    {
      title: "File for Incorporation",
      description: "File an application for incorporation with the Ministry of Corporate Affairs (MCA)."
    },
    {
      title: "Obtain Certificate of Incorporation",
      description: "Receive the certificate of incorporation from the MCA."
    },
    {
      title: "Update Records",
      description: "Update all relevant records and documents with the new company details."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#conversion" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Conversion Steps</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Conversion of Proprietorship Firm to Private Limited Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Converting your proprietorship firm to a Private Limited Company can offer significant benefits, including limited liability, enhanced credibility, and access to more funding opportunities. T2F provides expert assistance to make this transition seamless and compliant.
            </p>

            <p>10K+ Successful Conversions</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* <img src={Propfirm} alt='Proprietorship'/> */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Conversion of Proprietorship Firm to Private Limited Company</h6>
          <p className='text-center mt-4'>Converting a proprietorship firm to a Private Limited Company offers a range of advantages, including limited liability, increased credibility, and the ability to raise capital more effectively. T2F ensures a hassle-free conversion process with expert guidance at every step.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Conversion of Proprietorship Firm to Private Limited Company?</h6>

            <p className='mb-4'>Converting a proprietorship firm to a Private Limited Company involves transitioning from a sole proprietorship structure to a private limited company structure. This conversion allows for limited liability, enhanced credibility, and more options for raising capital.</p>
            <div>
              <ul><strong>Limited Liability:</strong> Shareholders’ liability is limited to their shares in the company.</ul>

              <ul><strong>Separate Legal Entity:</strong> The company is a separate legal entity from its owners.</ul>

              <ul><strong>Enhanced Credibility:</strong> Greater credibility and trust among clients and partners.</ul>

              <ul><strong>Ease of Raising Capital:</strong> Easier to raise capital through the sale of shares.</ul>

              <ul><strong>Structured Management:</strong> Structured management with defined roles and responsibilities.</ul>
            </div>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Conversion</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0C5E1' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Conversion</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of Conversion</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-2 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Conversion</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='conversion' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Convert</h2>
              <p>Follow these steps for a smooth conversion of your proprietorship firm to a Private Limited Company:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your Conversion?</h6>
            <p>T2F is your trusted partner for converting your proprietorship firm to a Private Limited Company:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you through the conversion process.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and PAN Assistance -</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to legal and regulatory requirements.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the conversion process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProprietorshipToPrivateLimitedNavbar;
