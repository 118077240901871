import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn7Navbar = ({ height }) => {

  // IT-Return 7 grid items
  const items = [
    { title: 'Income from Salary', description: 'Detailed assistance with reporting income from salary, including allowable deductions and exemptions.' },
    { title: 'Income from Property', description: 'Guidance on reporting income from property rentals, including deductions for maintenance and repairs.' },
    { title: 'Income from Investments', description: 'Advice on reporting income from investments such as fixed deposits, bonds, and mutual funds.' },
    { title: 'Income from Capital Gains', description: 'Instructions on reporting capital gains from the sale of assets, including the calculation of long-term and short-term gains.' },
    { title: 'Tax Credits and Deductions', description: 'Overview of available tax credits and deductions to optimize your tax liability and ensure compliance.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Income Reporting - Covers various types of income including salary, property, investments, and capital gains.",
    "Detailed Guidance - Provides detailed instructions for reporting and optimizing different income sources.",
    "Maximized Tax Benefits - Helps in identifying and claiming all eligible tax credits and deductions.",
    "Clear Reporting Process - Simplifies the reporting process with clear instructions and expert advice.",
    "Regulatory Compliance - Ensures compliance with tax regulations and timely filing of returns.",
  ];

  const disadvantages = [
    "Complex Documentation - Requires detailed documentation for different types of income and deductions.",
    "Potential Errors - Risk of errors if not all income sources and deductions are accurately reported.",
    "Additional Costs - May incur additional costs for specialized tax advice and filing services.",
  ];

  const documents = [
    {
      title: "Salary Slips",
      description: "Monthly salary slips or income statements from your employer for reporting salary income."
    },
    {
      title: "Property Rental Agreements",
      description: "Rental agreements and receipts for reporting income from property rentals."
    },
    {
      title: "Investment Statements",
      description: "Statements showing income from investments, including interest, dividends, and bonds."
    },
    {
      title: "Capital Gains Reports",
      description: "Reports or statements showing capital gains from the sale of assets such as property or shares."
    },
    {
      title: "Tax Credit Certificates",
      description: "Certificates for tax credits and deductions claimed, such as TDS certificates and investment proofs."
    }
  ];

  const steps = [
    {
      title: "Collect Income Documents",
      description: "Gather all necessary documents including salary slips, rental agreements, and investment statements."
    },
    {
      title: "Assess Income Sources",
      description: "Review all sources of income and determine applicable deductions and exemptions."
    },
    {
      title: "Complete IT-Return 7 Form",
      description: "Fill out the IT-Return 7 form with accurate information on income, deductions, and credits."
    },
    {
      title: "File the Return",
      description: "Submit the completed IT-Return 7 form electronically or manually as per tax regulations."
    },
    {
      title: "Verify Compliance",
      description: "Ensure compliance with all tax regulations and retain records of submission for future reference."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 7: Expert Filing Services
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              IT-Return 7 offers comprehensive filing solutions for reporting income from salary, property, investments, and capital gains. Our services help you navigate complex tax regulations and maximize your tax benefits.
            </p>

            <p>Efficiently handle your tax filing with IT-Return 7, ensuring all income sources are accurately reported and deductions are maximized.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IT-Return 7 Filing Services</h6>
          <p className='text-center mt-4'>Our IT-Return 7 services provide expert guidance on reporting income from salary, property, investments, and capital gains. Benefit from detailed assistance to ensure accurate reporting and optimize your tax benefits.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 7?</h6>

            <p className='mb-4'>IT-Return 7 is designed for individuals with diverse income sources, including salary, property, investments, and capital gains. It provides a comprehensive approach to tax filing, ensuring all income sources are reported and deductions are claimed.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for IT-Return 7</h6>
            <p className='mb-4'>To file IT-Return 7, you should meet the following criteria:
              <ul>
                <li><strong>Diverse Income Sources -</strong> Applicable if you have income from salary, property, investments, and capital gains.</li>

                <li><strong>Complete Documentation -</strong> Ensure you have all required documentation for accurate reporting and deduction claims.</li>

                <li><strong>Filing Requirements -</strong> Be aware of tax filing requirements and deadlines for IT-Return 7.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Key Features of IT-Return 7</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className="w-full px-16 mt-10 pt-2">
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of IT-Return 7</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for IT-Return 7</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>IT-Return 7 Filing Process</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ITReturn7Navbar;