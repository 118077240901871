import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import traderegis from './servicesIMG/TradeRigs.webp'
import ScrollToTopButton from './ScrollTop';

const TrademarkRegistrationNavbar = ({ height }) => {

  // Trademark grid items
  const items = [
    { title: 'Legal Protection', description: 'Trademark registration provides legal protection for your brand name and logo.' },
    { title: 'Brand Identity', description: 'Helps in establishing and maintaining your brand’s identity in the market.' },
    { title: 'Exclusive Rights', description: 'Grants exclusive rights to use the trademark in connection with the goods or services.' },
    { title: 'Market Advantage', description: 'Offers a competitive edge by differentiating your products or services.' },
    { title: 'Asset Creation', description: 'A registered trademark can become a valuable asset for your business.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Protection - Provides exclusive rights to use and protect your brand name and logo.",
    "Brand Recognition - Helps in building and maintaining your brand’s identity and reputation.",
    "Market Differentiation - Distinguishes your products or services from competitors in the market.",
    "Asset Value - A registered trademark can be a valuable asset and can be licensed or sold."
  ];

  const disadvantages = [
    "Cost - Trademark registration involves fees and ongoing maintenance costs.",
    "Time-Consuming - The process can take several months and involves detailed paperwork.",
    "Limited Scope - Protection is limited to the jurisdiction where the trademark is registered."
  ];

  const documents = [
    {
      title: "Business Registration Certificate",
      description: "Proof of business registration or establishment."
    },
    {
      title: "Logo/Brand Name",
      description: "The logo or brand name to be registered as a trademark."
    },
    {
      title: "Proof of Use",
      description: "Evidence that the trademark is being used in commerce (if applicable)."
    },
    {
      title: "Identity Proof",
      description: "Identity proof of the applicant (e.g., PAN card, Aadhar card)."
    },
    {
      title: "Power of Attorney",
      description: "Authorization for a trademark attorney or agent to act on behalf of the applicant."
    },
    {
      title: "Trademark Search Report",
      description: "A report confirming that the trademark is not already registered or in use."
    }
  ];

  const steps = [
    {
      title: "Conduct a Trademark Search",
      description: "Ensure the trademark is not already registered or similar to existing ones."
    },
    {
      title: "Prepare Application",
      description: "Prepare the trademark registration application with necessary details and documents."
    },
    {
      title: "File the Application",
      description: "Submit the application with the relevant trademark office."
    },
    {
      title: "Examination Process",
      description: "The trademark office will examine the application and may request additional information."
    },
    {
      title: "Publication in Trademark Journal",
      description: "If accepted, the trademark will be published in the Trademark Journal for opposition."
    },
    {
      title: "Receive Trademark Registration Certificate",
      description: "Once approved, receive the trademark registration certificate."
    },
    {
      title: "Renewal",
      description: "Trademark registration is valid for 10 years and requires renewal."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Trademark Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Trademark registration protects your brand name, logo, and other identifiers from unauthorized use and ensures your exclusive rights.
            </p>

            <p>10K+ Trademarks Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={traderegis} alt='traderegistration'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Trademark Registration Made Simple with T2F</h6>
          <p className='text-center mt-4'>Ready to protect your brand? T2F simplifies the trademark registration process, ensuring your brand’s identity is legally protected. Contact us today to start securing your trademark.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trademark Registration?</h6>

            <p className='mb-4'>Trademark registration provides legal protection for unique symbols, names, or logos associated with your products or services. It helps in distinguishing your brand from others and prevents unauthorized use. Here’s why trademark registration is important:</p>
            <div>
              <ul><strong>Legal Protection:</strong> Ensures exclusive rights to use your trademark and prevents others from using it.</ul>

              <ul><strong>Brand Identity:</strong> Helps in establishing and protecting your brand’s identity in the market.</ul>

              <ul><strong>Exclusive Rights:</strong> Grants legal rights to use the trademark in connection with your goods or services.</ul>
            </div>

            <p>Trademark registration is crucial for protecting your intellectual property and maintaining a competitive edge in the market.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a trademark, you need to meet the following criteria:

              <ul>
                <li><strong>Distinctive Mark -</strong> The trademark must be unique and distinguishable from existing trademarks.</li>

                <li><strong>Business Use -</strong> The trademark should be used or intended to be used in connection with your business goods or services.</li>

                <li ><strong>Trademark Search -</strong> Conduct a search to ensure the trademark is not already registered.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Trademark Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Trademark Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of Trademark Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='why' className='secondLLP pr-2 pl-16 mt-16'>
          <div className='Choosing'>
            <h6 className='text-blue-500 text-lg font-semibold'>Choosing Your Trademark</h6>
            <ul className='space-x-4'>
              <li><strong>Distinctive - </strong> Ensure your trademark is unique and not similar to existing marks.</li>

              <li><strong>Descriptive - </strong> Reflects your brand’s identity and business purpose clearly.</li>

              <li ><strong>Compliance -</strong> Must comply with trademark regulations and avoid generic terms.</li>
            </ul>
          </div>

          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Trademark Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='how' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth trademark registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your Trademark Registration</h6>
            <p>T2F is your trusted partner for trademark registration:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Guidance - </strong> Professional support with the trademark registration process.</li>

              <li><strong>Document Assistance - </strong> Help with gathering and preparing the necessary documents.</li>

              <li ><strong>Trademark Search -</strong> Conduct thorough searches to avoid conflicts with existing trademarks.</li>

              <li ><strong>Efficient Processing -</strong> Streamlined handling of your trademark application.</li>

              <li ><strong>Ongoing Support -</strong> Continuous assistance throughout the registration and compliance process.</li>

              <li ><strong>Renewal Assistance -</strong> Support with trademark renewal and maintenance.</li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default TrademarkRegistrationNavbar;
