import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const CompanyAddressChange = ({ height }) => {

  // Company Address Change grid items
  const items = [
    { title: 'Address Change Notification', description: 'Notify relevant authorities and stakeholders about the change of address.' },
    { title: 'Document Preparation', description: 'Prepare and update necessary documents reflecting the new address.' },
    { title: 'Legal Compliance', description: 'Ensure compliance with legal requirements and update records with regulatory bodies.' },
    { title: 'Update Business Registrations', description: 'Update address details in business registrations and licenses.' },
    { title: 'Communicate with Clients', description: 'Inform clients and partners about the address change to avoid disruptions.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Compliance - Ensure all legal and regulatory requirements are met.",
    "Efficiency - Streamline the process of updating your address across various platforms.",
    "Professionalism - Maintain a professional image by promptly updating your address.",
    "Minimize Disruptions - Avoid potential disruptions by informing stakeholders in advance.",
    "Centralized Process - Manage all aspects of the address change in a single process.",
  ];

  const disadvantages = [
    "Administrative Burden - The process can be administratively intensive and time-consuming.",
    "Potential Costs - There may be associated costs for updating documents and registrations.",
    "Risk of Oversight - There's a risk of missing some updates if not managed carefully.",
  ];

  const documents = [
    {
      title: "Address Change Form",
      description: "Official form required to notify authorities of the change of address."
    },
    {
      title: "Updated Business Registration Certificate",
      description: "Updated certificate reflecting the new address."
    },
    {
      title: "Proof of New Address",
      description: "Documents verifying the new address, such as utility bills or lease agreements."
    },
    {
      title: "Board Resolution",
      description: "Resolution from the board of directors approving the address change."
    },
    {
      title: "Updated Licenses and Permits",
      description: "Updated licenses and permits reflecting the new address."
    }
  ];

  const steps = [
    {
      title: "Prepare Address Change Form",
      description: "Fill out the official form required to notify authorities about the address change."
    },
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents, such as proof of the new address and board resolutions."
    },
    {
      title: "Submit Address Change Notification",
      description: "Submit the address change form and documents to relevant authorities and regulatory bodies."
    },
    {
      title: "Update Business Registrations",
      description: "Update your business registration certificates and other licenses to reflect the new address."
    },
    {
      title: "Notify Clients and Stakeholders",
      description: "Inform clients, partners, and stakeholders about the address change to ensure smooth communication."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Company Address Change
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Changing your company’s address is an essential administrative task that involves updating records with various authorities and stakeholders. Our service ensures that your address change is managed efficiently and in compliance with all legal requirements.
            </p>

            <p>Ensure a smooth transition with our expert assistance in updating your company's address.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Company Address Change</h6>
          <p className='text-center mt-4'>Changing your company’s address requires careful management to ensure that all records are updated appropriately. Our service helps you navigate this process efficiently and ensures compliance with all legal and regulatory requirements.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Company Address Change?</h6>

            <p className='mb-4'>Company Address Change involves updating your business’s registered address with regulatory authorities, updating records with clients and partners, and ensuring all necessary documentation reflects the new address.</p>
          </div>

          <div id="application" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Address Change</h6>
            <p className='mb-4'>To change your company’s address, follow these steps:

              <ul>
                <li><strong>Prepare Address Change Form -</strong> Complete the official form required for notifying authorities of the address change.</li>

                <li><strong>Gather Required Documents -</strong> Collect necessary documents such as proof of new address and board resolutions.</li>

                <li><strong>Submit Address Change Notification -</strong> Submit the form and documents to relevant authorities.</li>

                <li><strong>Update Business Registrations -</strong> Update your business registration certificates and licenses with the new address.</li>

                <li><strong>Notify Clients and Stakeholders -</strong> Inform clients, partners, and stakeholders of the address change to avoid disruptions.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Company Address Change Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our Company Address Change Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our Company Address Change Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for Company Address Change</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="process" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete Company Address Change</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default CompanyAddressChange;