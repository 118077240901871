import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const SEBIRegistration = ({ height }) => {

  // SEBI Registration grid items
  const items = [
    { title: 'Regulatory Compliance', description: 'Ensure compliance with SEBI regulations and guidelines.' },
    { title: 'Investor Protection', description: 'Enhance investor confidence and safeguard their interests.' },
    { title: 'Market Integrity', description: 'Promote fairness and transparency in the financial markets.' },
    { title: 'Corporate Governance', description: 'Adopt best practices in corporate governance as mandated by SEBI.' },
    { title: 'Enhanced Credibility', description: 'Boost your company’s credibility and trustworthiness in the market.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Regulatory Compliance - Adherence to SEBI regulations ensures legal operation and prevents penalties.",
    "Investor Confidence - Compliance with SEBI guidelines enhances trust and attracts more investors.",
    "Market Integrity - Promotes transparency and fairness in financial transactions.",
    "Corporate Governance - Implements best practices in governance to maintain high standards.",
    "Enhanced Credibility - Strengthens your company’s reputation in the financial sector."
  ];

  const disadvantages = [
    "Regulatory Complexity - Navigating SEBI regulations can be complex and time-consuming.",
    "Cost of Compliance - Ensuring compliance may involve additional costs and resources.",
    "Frequent Updates - SEBI guidelines may change, requiring continuous updates and adjustments.",
    "Documentation Requirements - Extensive documentation may be required for registration and compliance.",
    "Monitoring and Reporting - Ongoing monitoring and reporting obligations can be demanding."
  ];

  const documents = [
    {
      title: "Application Form",
      description: "Complete application form for SEBI registration."
    },
    {
      title: "Company Documents",
      description: "Incorporation certificate, MOA, AOA, and other relevant company documents."
    },
    {
      title: "Compliance Certificate",
      description: "Certificate of compliance with SEBI regulations."
    },
    {
      title: "Financial Statements",
      description: "Audited financial statements of the company."
    }
  ];

  const steps = [
    {
      title: "Prepare Documents",
      description: "Gather and prepare all necessary documents for SEBI registration."
    },
    {
      title: "Complete Application",
      description: "Fill out the SEBI registration application form with accurate details."
    },
    {
      title: "Submit to SEBI",
      description: "Submit the completed application form and documents to SEBI."
    },
    {
      title: "Review and Approval",
      description: "SEBI reviews the application and documents before granting registration."
    },
    {
      title: "Compliance Adherence",
      description: "Ensure ongoing compliance with SEBI regulations and guidelines post-registration."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              SEBI Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtaining SEBI registration is crucial for ensuring compliance with regulatory standards and fostering investor trust. Our team provides expert assistance throughout the registration process, making it smooth and efficient.
            </p>

            <p>Ensure your company's compliance and enhance its market credibility with our SEBI registration services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>SEBI Registration Made Simple</h6>
          <p className='text-center mt-4'>Ensure your company meets all SEBI regulatory requirements with our streamlined registration process. Gain investor confidence and maintain market integrity with our expert support.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is SEBI Registration?</h6>

            <p className='mb-4'>SEBI registration is a mandatory process for companies involved in securities and financial markets. It ensures adherence to regulations set by the Securities and Exchange Board of India, promoting transparency and investor protection.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To obtain SEBI registration, your company must:

              <ul>
                <li><strong>Complete Application Form -</strong> Fill out the SEBI registration application accurately.</li>

                <li><strong>Company Documents -</strong> Provide incorporation certificate, MOA, AOA, and other relevant documents.</li>

                <li><strong>Compliance Certificate -</strong> Submit a certificate of compliance with SEBI regulations.</li>

                <li><strong>Financial Statements -</strong> Include audited financial statements for the company.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of SEBI Registration</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of SEBI Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of SEBI Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for SEBI Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for SEBI Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for SEBI Registration?</h6>
            <p>We offer expert assistance in navigating the SEBI registration process, ensuring compliance with all regulatory requirements. Our efficient service minimizes disruptions to your business while enhancing your credibility in the financial market.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default SEBIRegistration;