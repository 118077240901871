import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import gstcancel from './servicesIMG/GSTcancel.webp'

const RegistrationCancellation = ({ height }) => {

  // Registration Cancellation / Revocation grid items
  const items = [
    { title: 'What is Registration Cancellation?', description: 'Registration cancellation is the process of formally terminating a registered entity’s status to cease its legal existence.' },
    { title: 'Who Can Apply for Revocation?', description: 'Typically, the registered entity, its representatives, or regulatory authorities can apply for revocation.' },
    { title: 'Reasons for Cancellation', description: 'Common reasons include non-compliance, prolonged inactivity, or failure to meet legal obligations.' },
    { title: 'Consequences of Cancellation', description: 'Cancellation leads to the removal of the entity’s legal status and the cessation of all associated rights and obligations.' },
    { title: 'Reinstatement Possibilities', description: 'Depending on the jurisdiction, there might be options to reinstate a canceled registration under certain conditions.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Preventing Legal Issues - Reduces risks associated with inactive or non-compliant registrations.",
    "Cost Efficiency - Eliminates ongoing costs related to maintaining unnecessary registrations.",
    "Operational Focus - Allows the entity to concentrate resources on active and compliant operations.",
    "Clarity in Legal Standing - Ensures legal records reflect only active and compliant entities."
  ];

  const disadvantages = [
    "Loss of Rights - Revocation results in the loss of legal rights and protections associated with the registration.",
    "Potential Financial Loss - May lead to financial repercussions or loss of business opportunities.",
    "Complex Process - The cancellation process can be complex and require extensive documentation.",
    "Difficulty in Reinstatement - Reinstating a revoked registration may be challenging and not always possible."
  ];

  const documents = [
    {
      title: "Proof of Non-Compliance",
      description: "Documentation showing the registered entity’s failure to comply with legal requirements."
    },
    {
      title: "Proof of Inactivity",
      description: "Evidence demonstrating that the entity or trademark has been inactive for a specified period."
    },
    {
      title: "Cancellation Request Form",
      description: "A formal request form for the cancellation or revocation of the registration."
    },
    {
      title: "Affidavits and Declarations",
      description: "Legal affidavits or declarations supporting the cancellation request."
    },
    {
      title: "Payment Evidence",
      description: "Receipts or proof of payment for any fees associated with the cancellation process."
    }
  ];

  const steps = [
    {
      title: "Assess Grounds for Cancellation",
      description: "Determine if there are valid reasons for canceling the registration based on inactivity or non-compliance."
    },
    {
      title: "Gather Required Documentation",
      description: "Collect all necessary documents, including proof of non-compliance and application forms."
    },
    {
      title: "Submit Cancellation Application",
      description: "File the cancellation application with the appropriate authority along with the required documentation."
    },
    {
      title: "Review and Decision",
      description: "The authority will review the application and make a decision regarding the cancellation."
    },
    {
      title: "Post-Cancellation Actions",
      description: "Complete any required follow-up actions to ensure the registration is properly removed from records."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Registration Cancellation / Revocation
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Cancel or revoke registrations when entities or trademarks fail to comply with legal requirements or become inactive.
            </p>

            <p>Ensure accurate legal records and avoid unnecessary costs by canceling registrations that are no longer needed.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '399px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={gstcancel} alt='gst cancellation'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Registration Cancellation / Revocation</h6>
          <p className='text-center mt-4'>The formal process of terminating the registration status of entities or trademarks, often due to inactivity or non-compliance.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Registration Cancellation?</h6>

            <p className='mb-4'>Registration cancellation involves formally terminating the legal status of a registered entity or trademark, leading to the cessation of its existence and associated rights.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Revocation</h6>
            <p className='mb-4'>Entities or trademarks that have failed to meet legal requirements or have been inactive for an extended period may be eligible for revocation.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Registration Cancellation</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Registration Cancellation</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Cancellation</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Cancellation</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default RegistrationCancellation;