import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import Producer from './servicesIMG/ProducerCom.webp'

const ProducerCompany = ({ height }) => {

  // Producer Company grid items
  const items = [
    { title: 'What is a Producer Company?', description: 'A Producer Company is a type of cooperative society formed to promote and organize production activities. It helps farmers and producers to work together, share resources, and market their products efficiently.' },
    { title: 'Benefits of Producer Companies', description: 'Producer Companies offer benefits like improved bargaining power, better access to markets, and enhanced resource sharing. They also help in pooling resources for common goals and reducing individual risks.' },
    { title: 'Registration Process', description: 'The process includes drafting the company’s charter, registering with the Registrar of Companies, and obtaining necessary licenses. Legal compliance and proper documentation are crucial for a successful registration.' },
    { title: 'Governance Structure', description: 'Producer Companies are governed by a board of directors elected by members. They adhere to democratic principles and ensure transparency in operations and decision-making.' },
    { title: 'Operational Guidelines', description: 'Operational guidelines include adherence to cooperative principles, maintaining financial records, and conducting regular meetings. Producer Companies must comply with statutory regulations and maintain member engagement.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Enhanced Bargaining Power - Collective effort improves negotiation capabilities with suppliers and buyers.",
    "Resource Sharing - Members can share resources like machinery, storage, and technology, reducing individual costs.",
    "Market Access - Facilitates better market access and marketing opportunities for members' products.",
    "Risk Reduction - Sharing risks among members lowers individual financial exposure.",
    "Support for Small Producers - Helps small producers gain access to larger markets and financial resources.",
  ];

  const disadvantages = [
    "Complex Setup - The registration and setup process can be complex and require significant documentation.",
    "Management Challenges - Effective management requires careful planning and coordination among members.",
    "Regulatory Compliance - Must adhere to various regulatory requirements and maintain compliance.",
    "Limited Scope - May have limitations in terms of the scale of operations and product diversification.",
    "Conflict Resolution - Differences among members can lead to conflicts that need effective resolution mechanisms.",
  ];

  const documents = [
    {
      title: "Charter Document",
      description: "A document outlining the objectives, structure, and rules of the Producer Company, essential for registration."
    },
    {
      title: "Member Agreements",
      description: "Agreements between members detailing their roles, contributions, and responsibilities within the company."
    },
    {
      title: "Registration Forms",
      description: "Forms required for registering the Producer Company with the Registrar of Companies."
    },
    {
      title: "Proof of Identity",
      description: "Identity proof of all members and directors for verification during registration."
    },
    {
      title: "Financial Statements",
      description: "Initial financial statements and projections for the company's operations and growth."
    }
  ];

  const steps = [
    {
      title: "Draft the Charter",
      description: "Prepare the charter document outlining the objectives, structure, and operational guidelines of the Producer Company."
    },
    {
      title: "Register with Authorities",
      description: "Submit the registration forms and required documents to the Registrar of Companies for official recognition."
    },
    {
      title: "Obtain Licenses",
      description: "Apply for and obtain necessary licenses and approvals for operating as a Producer Company."
    },
    {
      title: "Establish Governance",
      description: "Form a board of directors and establish governance practices according to cooperative principles."
    },
    {
      title: "Commence Operations",
      description: "Start the operational activities of the Producer Company, adhering to guidelines and maintaining member engagement."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Producer Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Producer Company is designed to help producers collaborate, share resources, and market their products effectively. It is a cooperative structure that supports collective production and business activities.
            </p>

            <p>Establish a Producer Company to enhance your operational efficiency and market access.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={Producer} alt='Producer Company'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Producer Company</h6>
          <p className='text-center mt-4'>A Producer Company enables producers to collaborate and leverage collective strength for better production and market opportunities. It is an ideal structure for those involved in agricultural and production activities.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Producer Company?</h6>

            <p className='mb-4'>A Producer Company is a type of cooperative designed to bring together producers to improve their production processes, share resources, and market their products effectively. It operates under cooperative principles and aims to benefit all its members.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Forming a Producer Company</h6>
            <p className='mb-4'>Any group of producers involved in agricultural, dairy, fishery, or other primary production activities can form a Producer Company. It requires a minimum of 10 producers or two or more producer institutions to initiate the formation.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of a Producer Company</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Producer Companies</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Producer Company Formation</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Forming a Producer Company</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default ProducerCompany;