import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const RBIRegistration = ({ height }) => {

  // RBI Registration grid items
  const items = [
    { title: 'Regulatory Oversight', description: 'Ensure compliance with RBI regulations and guidelines for financial institutions.' },
    { title: 'Financial Stability', description: 'Contribute to the stability and integrity of the financial system.' },
    { title: 'Investor Trust', description: 'Build trust among investors and stakeholders through RBI compliance.' },
    { title: 'Operational Guidelines', description: 'Adhere to RBI’s operational guidelines and reporting requirements.' },
    { title: 'Enhanced Credibility', description: 'Improve your company’s credibility in the financial sector with RBI registration.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Regulatory Oversight - Adherence to RBI regulations ensures compliance and avoids penalties.",
    "Financial Stability - Contributes to maintaining financial stability and integrity in the market.",
    "Investor Trust - Enhances trust among investors and stakeholders by meeting RBI standards.",
    "Operational Guidelines - Ensures that your operations align with RBI’s operational guidelines.",
    "Enhanced Credibility - Boosts your company’s reputation and credibility in the financial industry."
  ];

  const disadvantages = [
    "Regulatory Complexity - Understanding and adhering to RBI regulations can be complex and time-consuming.",
    "Cost of Compliance - May involve additional costs for compliance and reporting requirements.",
    "Frequent Updates - RBI regulations may change, requiring ongoing adjustments and updates.",
    "Documentation Requirements - Requires extensive documentation for registration and compliance.",
    "Monitoring and Reporting - Ongoing monitoring and reporting obligations can be demanding."
  ];

  const documents = [
    {
      title: "Application Form",
      description: "Complete application form for RBI registration."
    },
    {
      title: "Company Documents",
      description: "Incorporation certificate, MOA, AOA, and other relevant company documents."
    },
    {
      title: "Compliance Certificate",
      description: "Certificate of compliance with RBI regulations."
    },
    {
      title: "Financial Statements",
      description: "Audited financial statements of the company."
    },
    {
      title: "Business Plan",
      description: "Detailed business plan outlining your company’s financial operations."
    }
  ];

  const steps = [
    {
      title: "Prepare Documents",
      description: "Collect and prepare all necessary documents for RBI registration."
    },
    {
      title: "Complete Application",
      description: "Fill out the RBI registration application form with accurate details."
    },
    {
      title: "Submit to RBI",
      description: "Submit the completed application form and documents to the Reserve Bank of India."
    },
    {
      title: "Review and Approval",
      description: "RBI reviews the application and documents before granting registration."
    },
    {
      title: "Compliance Adherence",
      description: "Ensure ongoing adherence to RBI regulations and reporting requirements post-registration."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              RBI Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtaining RBI registration is essential for ensuring compliance with the Reserve Bank of India's regulations and standards. Our expert team provides comprehensive support throughout the registration process, ensuring a smooth and efficient experience.
            </p>

            <p>Strengthen your company’s credibility and maintain regulatory compliance with our RBI registration services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>RBI Registration Made Easy</h6>
          <p className='text-center mt-4'>Ensure your company meets RBI regulatory requirements with our streamlined registration process. Enhance your credibility and compliance with expert support every step of the way.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is RBI Registration?</h6>

            <p className='mb-4'>RBI registration is a mandatory process for companies operating in the financial sector in India. It ensures compliance with regulations set by the Reserve Bank of India, promoting financial stability and market integrity.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To obtain RBI registration, your company must:

              <ul>
                <li><strong>Complete Application Form -</strong> Accurately fill out the RBI registration application form.</li>

                <li><strong>Company Documents -</strong> Provide incorporation certificate, MOA, AOA, and other relevant documents.</li>

                <li><strong>Compliance Certificate -</strong> Submit a certificate confirming compliance with RBI regulations.</li>

                <li><strong>Financial Statements -</strong> Include audited financial statements for your company.</li>

                <li><strong>Business Plan -</strong> Provide a detailed business plan outlining your company’s financial operations.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of RBI Registration</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of RBI Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of RBI Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for RBI Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for RBI Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for RBI Registration?</h6>
            <p>Our expert team offers comprehensive assistance with RBI registration, ensuring that your company meets all regulatory requirements efficiently. We provide personalized support to streamline the process and enhance your credibility in the financial sector.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default RBIRegistration;