import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const BrokerLicense = ({ height }) => {

  // Broker License grid items
  const items = [
    { title: 'What is a Broker License?', description: 'A broker license grants legal authority to individuals or firms to engage in buying, selling, or renting real estate or financial assets on behalf of clients.' },
    { title: 'Who Can Apply for a Broker License?', description: 'Individuals or firms intending to act as intermediaries in real estate transactions or financial dealings can apply for a broker license.' },
    { title: 'Types of Broker Licenses', description: 'Broker licenses can be for real estate, insurance, or financial securities, depending on the area of specialization.' },
    { title: 'Validity and Renewal', description: 'Broker licenses are valid for a specific period and require periodic renewal to maintain legal status.' },
    { title: 'Regulatory Compliance', description: 'Licensed brokers must adhere to industry regulations and ethical standards to ensure fair dealing and consumer protection.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Standing - A broker license provides legal status to engage in intermediary transactions.",
    "Industry Credibility - Enhances trust and reputation in the market among clients and peers.",
    "Business Opportunities - Broadens the scope for business expansion in real estate or financial markets.",
    "Consumer Trust - A licensed broker builds confidence among clients for secure and reliable transactions."
  ];

  const disadvantages = [
    "Strict Regulations - The license process includes compliance with rigorous laws and industry standards.",
    "Ongoing Maintenance - Renewal and continued compliance require consistent effort and cost.",
    "High Costs - Fees for obtaining and renewing licenses can be high, especially for larger firms.",
    "Market Competition - Intense competition in the brokerage market can make sustaining the business challenging."
  ];

  const documents = [
    {
      title: "Application Form",
      description: "A completed and signed application form is necessary for starting the broker license process."
    },
    {
      title: "Proof of Business Address",
      description: "Evidence of the business’s registered address is required for verification."
    },
    {
      title: "Qualification Certificates",
      description: "Certificates showing the applicant's qualifications in brokerage, finance, or real estate."
    },
    {
      title: "Background Check",
      description: "Applicants must undergo a criminal background check to ensure they meet legal requirements."
    },
    {
      title: "License Fees",
      description: "Proof of payment for the associated broker license fees must be submitted."
    }
  ];

  const steps = [
    {
      title: "Meet Eligibility Criteria",
      description: "Ensure you meet the minimum requirements for education, experience, and background checks."
    },
    {
      title: "Submit Application",
      description: "Complete the application form and submit it along with the necessary documents."
    },
    {
      title: "Pass Licensing Exam",
      description: "For certain broker licenses, you must pass a government-mandated exam to qualify."
    },
    {
      title: "Receive Approval",
      description: "After verification and approval from the regulatory body, you will receive your license."
    },
    {
      title: "Maintain Compliance",
      description: "Stay compliant with all regulatory and renewal requirements to keep your license active."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Broker License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Obtain a broker license to legally operate in real estate or financial markets as an intermediary.
            </p>

            <p>Ensure compliance with regulatory standards while expanding your business opportunities in brokerage services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Broker License</h6>
          <p className='text-center mt-4'>A license required to legally act as a broker in real estate, financial, or insurance transactions.</p>
        </div>

          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Broker License?</h6>

            <p className='mb-4'>A broker license allows individuals or firms to legally operate as intermediaries in real estate, insurance, or financial transactions.</p>
          </div>

          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for a Broker License</h6>
            <p className='mb-4'>Applicants must meet educational, experience, and ethical requirements to qualify for a broker license.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of a Broker License</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of a Broker License</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Broker License</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((document, index) => (
                <Card key={index} title={document.title} description={document.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Obtain a Broker License</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>

      </div>

      <ScrollToTopButton />
    </>
  );
};

export default BrokerLicense;