import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const EInvoicing = ({ height }) => {

  // E-Invoicing grid items
  const items = [
    { title: 'Streamlined Process', description: 'Automate and streamline invoicing processes to enhance efficiency and accuracy.' },
    { title: 'Compliance Ready', description: 'Ensure compliance with all regulatory requirements through automated systems.' },
    { title: 'Real-Time Tracking', description: 'Track and manage invoices in real-time for better financial control.' },
    { title: 'Integration Capabilities', description: 'Seamlessly integrate with existing accounting and ERP systems.' },
    { title: 'Enhanced Security', description: 'Ensure secure and encrypted handling of all invoicing data.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Streamlined Process - Automate invoicing to reduce manual work and errors, improving overall efficiency.",
    "Compliance Ready - Built to meet all regulatory requirements, reducing the risk of compliance issues.",
    "Real-Time Tracking - Monitor invoices and financial transactions in real-time for better control and oversight.",
    "Integration Capabilities - Easily integrates with other accounting and ERP systems for seamless operations.",
    "Enhanced Security - Provides robust security features to protect sensitive invoicing data."
  ];

  const disadvantages = [
    "Initial Setup Cost - There may be a significant initial investment for software and integration.",
    "Training Required - Staff may need training to effectively use the new invoicing system.",
    "Technical Issues - Potential for technical issues or glitches during implementation and use.",
    "Dependence on Technology - Reliance on technology could lead to disruptions in case of system failures.",
    "Ongoing Maintenance - Regular updates and maintenance may be required to ensure smooth operation."
  ];

  const documents = [
    {
      title: "Business Registration Certificate",
      description: "Proof of business registration to set up invoicing processes."
    },
    {
      title: "Tax Identification Number",
      description: "Required for compliance and accurate invoicing."
    },
    {
      title: "Bank Account Details",
      description: "Necessary for managing payments and transactions."
    },
    {
      title: "Invoice Templates",
      description: "Templates for creating standardized invoices."
    },
    {
      title: "Compliance Documentation",
      description: "Documents showing compliance with local invoicing regulations."
    }
  ];

  const steps = [
    {
      title: "Assess Needs",
      description: "Evaluate your invoicing needs and choose the right e-invoicing solution."
    },
    {
      title: "Set Up System",
      description: "Implement the e-invoicing system and configure settings according to your business requirements."
    },
    {
      title: "Integrate with ERP",
      description: "Integrate the e-invoicing system with your existing ERP or accounting software."
    },
    {
      title: "Train Staff",
      description: "Provide training for staff to effectively use the new system."
    },
    {
      title: "Monitor and Optimize",
      description: "Continuously monitor the system and make necessary adjustments to improve efficiency."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              E-Invoicing Solutions
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Transform your invoicing process with our advanced e-invoicing solutions. Enhance efficiency, ensure compliance, and enjoy real-time tracking of all invoices.
            </p>

            <p>Our e-invoicing system is designed to automate and streamline your invoicing processes, integrating seamlessly with your existing systems and offering robust security features.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>E-Invoicing Made Efficient</h6>
          <p className='text-center mt-4'>Our e-invoicing solutions automate and streamline your invoicing processes, ensuring compliance with regulatory requirements and offering enhanced tracking and security features.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is E-Invoicing?</h6>

            <p className='mb-4'>E-Invoicing refers to the use of electronic systems to create, send, and manage invoices. Our solution helps businesses automate invoicing processes, ensure regulatory compliance, and improve financial tracking.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To implement e-invoicing, your business needs:

              <ul>
                <li><strong>Business Registration -</strong> A registered business entity to set up the invoicing system.</li>

                <li><strong>Tax Identification Number -</strong> Required for compliance and accurate invoicing.</li>

                <li><strong>Bank Account Details -</strong> For managing payments and transactions related to invoicing.</li>

                <li><strong>Invoice Templates -</strong> Standardized templates to ensure consistency in invoices.</li>

                <li><strong>Compliance Documentation -</strong> Documentation to demonstrate adherence to local invoicing regulations.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of E-Invoicing</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of E-Invoicing</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of E-Invoicing</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for E-Invoicing</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Implement E-Invoicing</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for E-Invoicing?</h6>
            <p>Our team offers comprehensive support in implementing e-invoicing solutions tailored to your business needs. We ensure a seamless integration with existing systems, provide training, and offer ongoing support to optimize your invoicing processes.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default EInvoicing;