import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const Refund = ({ height }) => {

  // Refund grid items
  const items = [
    { title: 'What is a Refund?', description: 'A refund is the process of returning money to a customer who is dissatisfied with a product or service. It involves reversing a transaction and returning the purchase amount to the original payment method.' },
    { title: 'Types of Refunds', description: 'Refunds can be categorized into full refunds, partial refunds, and store credit. Full refunds return the entire purchase amount, while partial refunds return a portion of the amount. Store credit refunds offer credit towards future purchases.' },
    { title: 'Refund Process', description: 'The refund process typically includes submitting a refund request, verifying the purchase, processing the return, and issuing the refund. The process may vary depending on the retailer or service provider’s policy.' },
    { title: 'Refund Policies', description: 'Different businesses have varying refund policies. Common policies include time limits for returns, conditions for returnable items, and methods of refund. It is important for consumers to be aware of these policies before making a purchase.' },
    { title: 'Handling Refund Disputes', description: 'In case of disputes, customers can contact customer service or escalate the issue through consumer protection agencies. Proper documentation and clear communication are crucial in resolving refund disputes.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Customer Satisfaction - Refunds help maintain customer trust and satisfaction by addressing issues with products or services.",
    "Flexible Options - Provides options for customers who may not be satisfied with their purchase.",
    "Improved Reputation - A fair refund policy can enhance the business’s reputation and customer loyalty.",
    "Risk Mitigation - Reduces the risk of negative reviews and potential legal issues related to unsatisfactory purchases.",
    "Encourages Sales - Flexible refund policies can encourage more sales by reducing customer hesitation.",
  ];

  const disadvantages = [
    "Potential Abuse - Some customers may abuse the refund policy, leading to financial losses for the business.",
    "Administrative Costs - Processing refunds involves administrative work and potential costs for the business.",
    "Impact on Profitability - Frequent refunds can impact the overall profitability of the business.",
    "Customer Expectations - Refund policies may create high expectations among customers, which can be challenging to manage.",
    "Policy Complexity - Complex refund policies can lead to confusion and disputes among customers and staff.",
  ];

  const documents = [
    {
      title: "Proof of Purchase",
      description: "A receipt or order confirmation to verify the original transaction and purchase details."
    },
    {
      title: "Refund Request Form",
      description: "A form that customers fill out to request a refund, including details about the reason for the refund."
    },
    {
      title: "Return Shipping Label",
      description: "If applicable, a shipping label for returning the product to the retailer or service provider."
    },
    {
      title: "Refund Policy Document",
      description: "The document outlining the business’s refund policy, including terms and conditions for refunds."
    },
    {
      title: "Customer Communication Records",
      description: "Records of communication between the customer and the business regarding the refund request and resolution."
    }
  ];

  const steps = [
    {
      title: "Submit Refund Request",
      description: "Fill out and submit the refund request form along with any required documentation."
    },
    {
      title: "Verify Purchase",
      description: "Verify the original purchase using proof of purchase and other relevant information."
    },
    {
      title: "Process Return",
      description: "If applicable, process the return of the product or service as per the refund policy."
    },
    {
      title: "Issue Refund",
      description: "Issue the refund to the original payment method or as store credit, depending on the policy."
    },
    {
      title: "Communicate with Customer",
      description: "Notify the customer of the refund status and provide any additional information if necessary."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Refund
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A refund process ensures that customers can return products or services and get their money back. It is essential for customer satisfaction and maintaining a good business reputation.
            </p>

            <p>Streamline your refund process to improve customer experience and manage returns effectively.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Refund</h6>
          <p className='text-center mt-4'>A refund is an essential customer service function that allows for the return of products or services and the reimbursement of the purchase amount. It is important for customer satisfaction and maintaining a positive business image.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Refund?</h6>

            <p className='mb-4'>A refund is a monetary return to a customer who is dissatisfied with a product or service. It involves reversing a transaction and returning the purchase amount to the original payment method. Refunds can help resolve customer complaints and enhance satisfaction.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Refund</h6>
            <p className='mb-4'>Eligibility for a refund typically depends on the business’s refund policy. Common criteria include the condition of the returned item, the time elapsed since purchase, and adherence to the return procedure.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Refunds</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Refunds</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Refund</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Refund</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Refund;