import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import Nidhi from './servicesIMG/NidhiCompany.webp'
const NidhiNavbar = ({ height }) => {

  // Nidhi service grid items
  const items = [
    { title: 'Regulated by RBI', description: 'Nidhi companies are regulated by the Reserve Bank of India (RBI).' },
    { title: 'Promotes Savings', description: 'Encourages people to save money and participate in community financial activities.' },
    { title: 'Loan Facilities', description: 'Provides various types of loans to its members.' },
    { title: 'Low Capital Requirement', description: 'Requires lower capital compared to other financial institutions.' },
    { title: 'Member-Focused', description: 'Only offers services to its members, creating a community-centric approach.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Regulated Operations - Adheres to RBI guidelines ensuring regulated and safe operations.",
    "Encourages Savings - Promotes savings among members through attractive schemes.",
    "Community Focused - Operates on a mutual benefit principle among members.",
    "Affordable Setup - Lower initial investment compared to banks and other financial institutions."
  ];

  const disadvantages = [
    "Limited Scope - Services are restricted to members only, limiting outreach.",
    "Regulatory Constraints - Must adhere to stringent RBI regulations which can be complex.",
    "Operational Risks - Dependency on member participation and financial health of the community."
  ];

  const documents = [
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      title: "Address Proof",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Residential Proof",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      title: "PAN Card/ID Proof",
      description: "For all directors and members."
    },
    {
      title: "Digital Signature Certificate (DSC)",
      description: "Required for at least one director."
    }
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for all directors."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For all directors."
    },
    {
      title: "Choose and Reserve Your Nidhi Name",
      description: "Ensure it meets the RBI guidelines."
    },
    {
      title: "Complete Form Nidhi",
      description: "Submit information about the Nidhi, directors, and registered office."
    },
    {
      title: "Draft and File Nidhi Agreement",
      description: "Must be notarized and filed within 30 days of incorporation."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Nidhi Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Nidhi Company is a type of non-banking financial company that focuses on savings and loans among its members.
            </p>

            <p>10K+ Trusted Companies Successfully Registered</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
             <img src={Nidhi} alt='Nidhi Company'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Nidhi Registration Made Simple with T2F</h6>
          <p className='text-center mt-4'>Ready to establish a Nidhi Company that fosters savings and financial activities? Look no further than T2F. With Nidhi Companies, you get a unique model focused on mutual benefit and regulated by the RBI. At T2F, we simplify the Nidhi registration process, guiding you through every step to ensure a smooth setup. Contact us today to start your Nidhi journey.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Nidhi?</h6>

            <p className='mb-4'>A Nidhi Company is a type of non-banking financial company that promotes the habit of saving among its members and provides loan facilities. Here’s what makes Nidhi Companies unique:</p>
            <div>
              <ul><strong>Regulated by RBI:</strong> Nidhi Companies are governed by the Reserve Bank of India, ensuring regulated operations.</ul>

              <ul><strong>Promotes Savings:</strong> Focuses on encouraging savings among its members.</ul>

              <ul><strong>Loan Facilities:</strong> Provides loans to its members with favorable terms.</ul>
            </div>

            <p>Introduced under the Nidhi Companies Rules, Nidhi Companies offer a community-based approach to financial services.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a Nidhi Company in India, you need to meet the following criteria:

              <ul>
                <li><strong>Minimum 7 Members -</strong> A Nidhi Company must have at least seven members at the time of registration.</li>

                <li><strong>Minimum 3 Directors -</strong> At least three directors are required, with a majority being Indian residents.</li>

                <li ><strong>Paid-Up Capital -</strong>A minimum paid-up capital of Rs. 5 lakh is required.</li>
                <li ><strong>Company Name -</strong> The name must include “Nidhi Limited” at the end.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Nidhi Company</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of a Nidhi Company</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of a Nidhi Company</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='why' className='secondLLP pr-2 pl-16 mt-16'>
          <div className='Choosing'>
            <h6 className='text-blue-500 text-lg font-semibold'>Choosing your Nidhi Name</h6>
            <ul className='space-x-4'>
              <li><strong>Unique and Available - </strong> Ensure your Nidhi name is unique and not already in use.</li>

              <li><strong>Descriptive - </strong> Reflects your financial activities clearly.</li>

              <li ><strong>Suffix Requirement -</strong>Must end with “Nidhi Limited”</li>
            </ul>
          </div>

          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Nidhi Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='how' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth registration process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Nidhi Registration</h6>
            <p>T2F is your trusted partner for Nidhi registration:</p>
            <ul className='space-x-4'>
              <li><strong>Nidhi Agreement Drafting - </strong> Expert help with drafting and filing your Nidhi agreement.</li>

              <li><strong>Name Reservation - </strong> We’ll help you secure your desired Nidhi name.</li>

              <li ><strong>Expert Support -</strong> Our experienced team ensures a smooth registration process.</li>

              <li ><strong>Ongoing Assistance -</strong> We provide updates and support throughout your registration journey.</li>

              <li ><strong>DSC and DIN Assistance -</strong> We facilitate obtaining necessary digital signatures and identification numbers.</li>

              <li ><strong>PAN and TAN Application -</strong> Simplified application process for tax IDs.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default NidhiNavbar;
