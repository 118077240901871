import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import designregis from './servicesIMG/Designregis.webp'

const DesignNavbar = ({ height }) => {

  // Design Registration service grid items
  const items = [
    { title: 'Exclusive Rights', description: 'Provides exclusive rights to the design, preventing others from copying or imitating it.' },
    { title: 'Legal Protection', description: 'Offers legal protection against infringement and unauthorized use of the design.' },
    { title: 'Market Differentiation', description: 'Helps differentiate your product in the market with a unique and protected design.' },
    { title: 'Increases Brand Value', description: 'Enhances the value of your brand by securing distinctive and appealing designs.' },
    { title: 'Encourages Creativity', description: 'Promotes innovation and creativity by protecting original design concepts.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Exclusive Rights - Design registration grants exclusive rights to the design, preventing others from using or copying it without permission.",
    "Legal Protection - Provides legal mechanisms to enforce your rights and address any infringement or unauthorized use.",
    "Market Differentiation - Helps your product stand out in the market by protecting unique and distinctive design elements.",
    "Increases Brand Value - Enhances your brand’s value and recognition by securing original and attractive design features.",
    "Encourages Creativity - Protects innovative and creative designs, fostering further development and originality.",
  ];

  const disadvantages = [
    "Cost - The process of registering a design can incur costs, including application fees and legal expenses.",
    "Limited Scope - Design protection is limited to the visual appearance and does not cover functional aspects of the product.",
    "Disclosure of Design - Requires public disclosure of the design, which may expose it to competitors before registration is complete.",
    "Regional Limitations - Design registration is generally limited to specific countries or regions, requiring separate applications for international protection.",
  ];

  const documents = [
    {
      title: "Design Application Form",
      description: "Complete the official design registration application form provided by the intellectual property office."
    },
    {
      title: "Design Drawings/Images",
      description: "Provide clear and detailed drawings or images of the design from different angles."
    },
    {
      title: "Description of the Design",
      description: "Include a detailed description of the design, explaining its visual features and unique elements."
    },
    {
      title: "Proof of Identity",
      description: "Submit proof of identity of the applicant or the company applying for the design registration."
    },
    {
      title: "Payment of Fees",
      description: "Pay the required application fee as per the intellectual property office’s guidelines."
    }
  ];

  const steps = [
    {
      title: "Prepare Design Documentation",
      description: "Gather and prepare all necessary documents and drawings required for the design registration."
    },
    {
      title: "File Design Application",
      description: "Submit the design application along with the required documents to the intellectual property office."
    },
    {
      title: "Examination Process",
      description: "Undergo the examination process where the office reviews the application to ensure it meets registration criteria."
    },
    {
      title: "Respond to Objections",
      description: "Address any objections or requests for additional information raised by the examiner."
    },
    {
      title: "Receive Design Registration",
      description: "Upon successful review, receive the design registration and enjoy exclusive rights to your design."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Design Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Design registration provides legal protection for the unique visual appearance of a product, ensuring that your design is safeguarded against unauthorized copying and use. It helps differentiate your product in the market and adds value to your brand.
            </p>

            <p>Countless designers and businesses have protected their unique designs with our assistance.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
               <img src={designregis} alt='Design Registration'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Design Registration Made Simple</h6>
          <p className='text-center mt-4'>Design registration is essential for protecting the unique visual aspects of your product. We provide a streamlined process to ensure your designs are legally protected and secure in the marketplace.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Design Registration?</h6>

            <p className='mb-4'>Design registration is the process of securing legal rights for the unique visual appearance of a product. This includes shapes, patterns, and colors that distinguish your product from others in the market. It ensures that your design cannot be copied or imitated without your permission.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Design Registration</h6>
            <p className='mb-4'>To apply for design registration, you must meet the following requirements:

              <ul>
                <li><strong>Design Application Form -</strong> Complete the official design registration application form provided by the intellectual property office.</li>

                <li><strong>Design Drawings/Images -</strong> Submit clear and detailed drawings or images of the design from different angles.</li>

                <li><strong>Description of the Design -</strong> Provide a detailed description of the design, including its visual features and unique elements.</li>

                <li><strong>Proof of Identity -</strong> Submit proof of identity of the applicant or the company applying for the design registration.</li>

                <li><strong>Payment of Fees -</strong> Pay the required application fee as per the intellectual property office’s guidelines.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Design Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Design Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Design Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Design Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for Design Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default DesignNavbar;