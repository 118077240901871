import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import esi from './servicesIMG/ESI.webp'
import ScrollToTopButton from './ScrollTop';

const ESIRegistration = ({ height }) => {

  // ESI Registration grid items
  const items = [
    { title: 'What is ESI Registration?', description: 'Employee State Insurance (ESI) is a self-financed social security scheme for Indian workers.' },
    { title: 'Who Needs ESI?', description: 'Employers with more than 10 employees earning below a specified wage limit must register for ESI.' },
    { title: 'Key Features of ESI', description: 'ESI provides medical and financial benefits to employees and their dependents.' },
    { title: 'Coverage', description: 'ESI covers employees earning less than ₹21,000 per month in factories and establishments.' },
    { title: 'Legal Governance', description: 'ESI is governed by the Employee State Insurance Act, 1948, ensuring mandatory coverage and contributions.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Medical Benefits - Provides comprehensive medical care to employees and their families.",
    "Sickness Benefits - Offers cash benefits during medical leave due to illness or injury.",
    "Maternity Benefits - Female employees can claim maternity leave and medical care.",
    "Disability Benefits - Employees who are disabled due to injury or illness are supported with cash compensation."
  ];

  const disadvantages = [
    "Limited Eligibility - ESI is only available to employees earning below a specified wage limit.",
    "Complex Compliance - Employers need to ensure correct filing and contributions, which adds to administrative burdens.",
    "Limited Control - Employees may have to use the ESI hospitals or dispensaries, restricting their healthcare options.",
    "Contribution Costs - Both employers and employees must contribute to the ESI fund, impacting payroll."
  ];

  const documents = [
    {
      title: "Employer Registration",
      description: "Employers need to register themselves under the Employee State Insurance Act with the necessary documents."
    },
    {
      title: "Employee Details",
      description: "The details of all employees, including their salaries and identification, must be provided."
    },
    {
      title: "Factory or Establishment License",
      description: "A valid license showing the nature of the business is required for ESI registration."
    },
    {
      title: "Proof of Address",
      description: "Documents verifying the address of the business or establishment must be submitted."
    },
    {
      title: "Contribution Details",
      description: "Employers need to provide details of contributions paid towards the ESI fund."
    }
  ];

  const steps = [
    {
      title: "Obtain Employer Code",
      description: "The employer must apply for an Employer Code from the ESIC (Employee State Insurance Corporation)."
    },
    {
      title: "Employee Registration",
      description: "The employer registers all eligible employees for ESI, providing their details and contributions."
    },
    {
      title: "Submit Documents",
      description: "Submit all required documents to the regional ESIC office, ensuring compliance with the law."
    },
    {
      title: "Verification by ESIC",
      description: "ESIC officials review the documents and may inspect the premises for confirmation."
    },
    {
      title: "Issue ESI Number",
      description: "Each employee is assigned a unique ESI number for future reference and benefits."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{" top": '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              ESI Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Secure health and social benefits for your employees through ESI registration under the ESIC.
            </p>

            <p>Ensure your organization complies with ESI regulations and provides essential medical coverage for your workforce.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={esi} alt='ESI Registration'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>ESI Registration</h6>
          <p className='text-center mt-4'>A legal requirement for employers to provide medical and social security benefits to employees.</p>
        </div>

          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is ESI Registration?</h6>

            <p className='mb-4'>ESI registration ensures that employees have access to medical care and financial benefits in case of illness or injury.</p>
          </div>

          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for ESI Registration</h6>
            <p className='mb-4'>All establishments with 10 or more employees must register for ESI if their employees' earnings fall below ₹21,000 per month.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of ESI Registration</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of ESI Registration</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className='advantages-section mb-10' style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for ESI Registration</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((document, index) => (
                <Card key={index} title={document.title} description={document.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Register for ESI</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default ESIRegistration;