import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const NitiAayogRegistration = ({ height }) => {

  // Niti Aayog Registration grid items
  const items = [
    { title: 'What is Niti Aayog Registration?', description: 'Niti Aayog is a government think tank that provides NGOs and other organizations a platform for transparency and governance.' },
    { title: 'Who Needs Niti Aayog?', description: 'Non-profit organizations, NGOs, and social welfare entities looking for government collaborations and funding must register with Niti Aayog.' },
    { title: 'Key Features of Niti Aayog', description: 'The registration helps organizations become more transparent, secure government recognition, and apply for various government schemes.' },
    { title: 'Eligibility for Niti Aayog', description: 'Any non-profit, NGO, or social welfare organization that operates for the benefit of society is eligible for Niti Aayog registration.' },
    { title: 'Legal Governance', description: 'Niti Aayog ensures that NGOs and non-profits are compliant with government regulations, ensuring ethical and legal conduct.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Access to Government Schemes - Registered NGOs can apply for various government welfare schemes.",
    "Increased Credibility - Being listed with Niti Aayog improves the credibility of an organization.",
    "Transparency - It ensures that registered NGOs operate transparently and ethically.",
    "Recognition - Niti Aayog registration provides official recognition and support from the government."
  ];

  const disadvantages = [
    "Strict Regulations - Organizations must adhere to strict reporting and auditing requirements.",
    "Limited to Non-profits - Only non-profit organizations can register with Niti Aayog.",
    "Long Process - The registration and compliance process may take time and requires thorough documentation.",
    "Non-compliance Penalties - Failure to comply with rules may result in removal from the Niti Aayog list."
  ];

  const documents = [
    {
      title: "Organization Registration Certificate",
      description: "NGOs must submit their registration certificate or trust deed during the application process."
    },
    {
      title: "Financial Statements",
      description: "Past financial records demonstrating transparency and ethical use of funds must be submitted."
    },
    {
      title: "List of Board Members",
      description: "A list of the organization's board members or trustees needs to be provided."
    },
    {
      title: "Work Report",
      description: "Organizations must submit a detailed report of their past activities and ongoing projects."
    },
    {
      title: "PAN and Aadhar Details",
      description: "The PAN and Aadhar details of the key members must be shared during the registration process."
    }
  ];

  const steps = [
    {
      title: "Online Application",
      description: "NGOs must apply online through the Niti Aayog Darpan portal and fill in the required details."
    },
    {
      title: "Document Submission",
      description: "Submit the required documents, including registration certificates, financial reports, and work reports."
    },
    {
      title: "Verification Process",
      description: "The submitted documents are verified by Niti Aayog to ensure authenticity and compliance."
    },
    {
      title: "Approval and Issue of Registration",
      description: "Once verified, Niti Aayog provides approval and issues a registration number for the organization."
    },
    {
      title: "Maintain Compliance",
      description: "NGOs must file regular reports and maintain compliance with Niti Aayog's regulations."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Niti Aayog Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Secure recognition and support from the Government of India by registering with Niti Aayog.
            </p>

            <p>Ensure your NGO or social organization is recognized by the government for transparency and better funding opportunities.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-full h-[15%] ">
          <h6 className='text-2xl text-center font-semibold'>Niti Aayog Registration</h6>
          <p className='text-center mt-4'>Get recognized by the Government of India for your social work and enhance your organization's credibility.</p>
        </div>

          <div className=' px-pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Niti Aayog Registration?</h6>

            <p className='mb-4'>Niti Aayog is a central government initiative to support and provide a transparent platform for NGOs and other social welfare organizations.</p>
          </div>

          <div id="el" className='mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Niti Aayog Registration</h6>
            <p className='mb-4'>Non-profit organizations, NGOs, and entities engaged in social welfare activities are eligible for Niti Aayog registration.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Niti Aayog Registration</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Niti Aayog Registration</h6>
            <div className='flex flex-wrap justify-between'>
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Niti Aayog Registration</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((document, index) => (
                <Card key={index} title={document.title} description={document.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Register for Niti Aayog</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default NitiAayogRegistration;