import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const ITReturn2 = ({ height }) => {

  // IT-Return 2 grid items
  const items = [
    { title: 'Income from Capital Gains', description: 'Declare your income from the sale of assets like stocks or property.' },
    { title: 'Foreign Income Reporting', description: 'Report your income from any foreign sources or foreign assets.' },
    { title: 'Multiple Source Income', description: 'Include income from salary, business, and multiple other sources.' },
    { title: 'House Property', description: 'Declare your income from house property for tax computation.' },
    { title: 'Hassle-Free E-Filing', description: 'File IT-Return 2 online with complete accuracy and ease.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Reporting - IT-Return 2 allows for reporting of capital gains and foreign income.",
    "Multiple Income Sources - Ideal for those with diversified income streams.",
    "Tax Benefits - Maximize deductions and minimize tax liability by declaring all sources of income.",
    "Ease of Filing - File your return online with a step-by-step guide.",
    "Avoid Legal Complications - Accurate reporting of income avoids legal issues and penalties."
  ];

  const disadvantages = [
    "More Complex - IT-Return 2 requires detailed reporting of capital gains and foreign income.",
    "Penalties - Failure to report income or filing late can result in heavy fines.",
    "Time-Consuming - Gathering all necessary documents may take time and effort.",
    "Documentation - Extensive documentation is required for reporting multiple income sources.",
    "Tax Rules Complexity - Understanding ever-changing tax rules and regulations can be challenging."
  ];

  const documents = [
    {
      title: "Capital Gains Documents",
      description: "Documents related to the sale of assets like property or shares."
    },
    {
      title: "Form 16",
      description: "Salary certificate showing income and deductions from your employer."
    },
    {
      title: "Foreign Income Proof",
      description: "Proof of income earned from foreign sources, if applicable."
    },
    {
      title: "Property Documents",
      description: "Documents related to income from house property."
    },
    {
      title: "TDS Certificates",
      description: "Certificates for tax deducted at source on income from various sources."
    }
  ];

  const steps = [
    {
      title: "Collect All Necessary Documents",
      description: "Gather all documents related to capital gains, foreign income, salary, and house property."
    },
    {
      title: "Calculate Income from All Sources",
      description: "Include income from salary, house property, capital gains, and other sources."
    },
    {
      title: "Claim Applicable Deductions",
      description: "Maximize your tax savings by claiming all eligible deductions."
    },
    {
      title: "Pay Applicable Taxes",
      description: "Ensure that you have paid all advance taxes due to avoid penalties."
    },
    {
      title: "File IT-Return 2 Online",
      description: "Submit your income tax return online before the due date to avoid any late fees."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              IT-Return 2
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              File your IT-Return 2 accurately by declaring income from multiple sources, including capital gains, foreign assets, and house property.
            </p>

            <p>IT-Return 2 is ideal for individuals who earn income from various sources, including capital gains and foreign income. Our service ensures accurate filing, maximizing deductions and minimizing tax liabilities.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainITReturn w-full flex flex-wrap'>
        <div id="overview" className="ITReturnRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>IT-Return 2 Filing Made Easy</h6>
          <p className='text-center mt-4'>Our expert assistance helps you file IT-Return 2 seamlessly, ensuring all income sources are accurately reported, and eligible deductions are claimed for maximum tax savings.</p>
        </div>

        <div className='ITReturn-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is IT-Return 2?</h6>

            <p className='mb-4'>IT-Return 2 is for individuals who earn income from sources like capital gains, foreign assets, or multiple sources, including house property and salary. Filing ensures legal compliance and tax benefits.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To file IT-Return 2, the following documents and eligibility criteria are required:

              <ul>
                <li><strong>Capital Gains Documents -</strong> Proof of sale of assets.</li>

                <li><strong>Foreign Income Proof -</strong> Income earned from foreign assets or sources.</li>

                <li><strong>Property Documents -</strong> Proof of house property income, if applicable.</li>

                <li><strong>PAN Card -</strong> Required for tax identification.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of IT-Return 2</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 pr-2 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Filing IT-Return 2</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Filing IT-Return 2</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for IT-Return 2</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to File IT-Return 2</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Filing IT-Return 2?</h6>
            <p>Our team provides expert guidance and support to ensure seamless and accurate filing of IT-Return 2, maximizing tax savings and legal compliance.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default ITReturn2;