import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';

const DrugLicense = ({ height }) => {

  // Drug License grid items
  const items = [
    { title: 'What is a Drug License?', description: 'A drug license is a legal authorization granted by the government to entities involved in the sale, manufacture, and distribution of pharmaceuticals.' },
    { title: 'Who Can Apply for a Drug License?', description: 'Pharmaceutical companies, chemists, and healthcare providers involved in the sale or distribution of medicines can apply for a drug license.' },
    { title: 'Types of Drug Licenses', description: 'There are various types of licenses, including retail, wholesale, and manufacturing licenses, depending on the nature of the business.' },
    { title: 'Validity and Renewal', description: 'Drug licenses are typically valid for a certain period and require renewal to remain active and compliant.' },
    { title: 'Regulatory Compliance', description: 'Entities holding a drug license must adhere to the standards set by regulatory authorities to ensure safety and quality.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Authorization - Provides a legal framework for selling and distributing medicines.",
    "Consumer Trust - Increases trust and credibility with customers by ensuring compliance with laws.",
    "Expansion Opportunities - Allows businesses to expand their pharmaceutical operations legally.",
    "Market Recognition - A drug license improves market visibility and access to a wider consumer base."
  ];

  const disadvantages = [
    "Strict Regulations - The process involves adhering to stringent legal and regulatory requirements.",
    "Ongoing Compliance - Maintaining the license requires regular inspections and compliance checks.",
    "High Costs - Obtaining and maintaining the license can be expensive due to legal fees and compliance costs.",
    "Complex Procedures - The application and renewal processes may be time-consuming and complicated."
  ];

  const documents = [
    {
      title: "Application Form",
      description: "A completed application form is the first requirement for obtaining a drug license."
    },
    {
      title: "Proof of Business Premises",
      description: "Documentation providing proof of ownership or rental of the business premises."
    },
    {
      title: "Qualification Certificates",
      description: "Certificates showing the qualifications of the applicant in handling pharmaceutical products."
    },
    {
      title: "Affidavits and Declarations",
      description: "Legal affidavits or declarations supporting the application for a drug license."
    },
    {
      title: "Payment Evidence",
      description: "Receipts or proof of payment for any fees associated with the drug license application."
    }
  ];

  const steps = [
    {
      title: "Determine License Type",
      description: "Identify the appropriate type of drug license based on your business activity, such as retail or wholesale."
    },
    {
      title: "Submit Application",
      description: "Complete and submit the application form along with the required documents to the regulatory authority."
    },
    {
      title: "Inspection and Verification",
      description: "Regulatory authorities may conduct inspections of your premises to ensure compliance."
    },
    {
      title: "Review and Approval",
      description: "Authorities will review your application and, upon meeting all requirements, approve the drug license."
    },
    {
      title: "Maintain Compliance",
      description: "Ensure ongoing compliance with regulations and renew your license as required."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Drug License
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Secure a drug license to legally sell, distribute, or manufacture pharmaceutical products.
            </p>

            <p>Ensure your business complies with government regulations and operates smoothly in the pharmaceutical sector.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Drug License</h6>
          <p className='text-center mt-4'>The legal authorization required for selling, manufacturing, or distributing pharmaceutical products.</p>
        </div>
          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Drug License?</h6>

            <p className='mb-4'>A drug license allows businesses to legally operate within the pharmaceutical industry, ensuring compliance with health and safety regulations.</p>
          </div>

          <div id="el" className='mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for a Drug License</h6>
            <p className='mb-4'>Entities involved in the sale, distribution, or manufacturing of pharmaceutical products are eligible to apply for a drug license.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of a Drug License</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of a Drug License</h6>
            <div className="flex flex-wrap justify-between mt-10">
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Drug License</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((document, index) => (
                <Card key={index} title={document.title} description={document.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Obtain a Drug License</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

export default DrugLicense;