import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import iso from './servicesIMG/ISO.webp'

const ISOCertificationNavbar = ({ height }) => {

  // ISO Certification grid items
  const items = [
    { title: 'Global Recognition', description: 'ISO certification is recognized worldwide, enhancing your business’s credibility.' },
    { title: 'Quality Assurance', description: 'Ensures that your processes meet international quality standards.' },
    { title: 'Customer Satisfaction', description: 'Improves customer satisfaction through consistent quality.' },
    { title: 'Operational Efficiency', description: 'Optimizes your processes, leading to increased efficiency and reduced waste.' },
    { title: 'Market Advantage', description: 'Provides a competitive edge by demonstrating commitment to quality.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Global Recognition - ISO certification is recognized internationally, boosting your credibility and trust in the global market.",
    "Quality Assurance - Ensures your processes and products meet internationally accepted standards.",
    "Customer Satisfaction - Enhances customer satisfaction through consistent quality and improved service.",
    "Operational Efficiency - Helps in streamlining processes, reducing waste, and improving overall efficiency."
  ];

  const disadvantages = [
    "Cost - Involves certification fees and potential costs for implementing required changes.",
    "Time-Consuming - The certification process can be lengthy and require significant time and effort.",
    "Maintenance - Requires ongoing compliance and periodic audits to maintain certification."
  ];

  const documents = [
    {
      title: "Quality Management Manual",
      description: "Document detailing your quality management system and procedures."
    },
    {
      title: "Procedures and Policies",
      description: "Written procedures and policies relevant to ISO standards."
    },
    {
      title: "Training Records",
      description: "Proof of training provided to employees on quality management systems."
    },
    {
      title: "Internal Audit Reports",
      description: "Records of internal audits conducted to assess compliance with ISO standards."
    },
    {
      title: "Management Review Records",
      description: "Records of management reviews and actions taken to address issues."
    }
  ];

  const steps = [
    {
      title: "Understand ISO Standards",
      description: "Familiarize yourself with the ISO standards relevant to your business."
    },
    {
      title: "Gap Analysis",
      description: "Conduct a gap analysis to identify areas needing improvement to meet ISO standards."
    },
    {
      title: "Implement Changes",
      description: "Make necessary changes to your processes and documentation to comply with ISO standards."
    },
    {
      title: "Internal Audits",
      description: "Conduct internal audits to ensure compliance and readiness for certification."
    },
    {
      title: "Select Certification Body",
      description: "Choose an accredited certification body to perform the certification audit."
    },
    {
      title: "Certification Audit",
      description: "Undergo the certification audit conducted by the chosen certification body."
    },
    {
      title: "Receive Certificate",
      description: "Upon successful audit, receive the ISO certification."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              ISO 
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              ISO certification demonstrates your commitment to quality and international standards, enhancing your business’s credibility and operational efficiency.
            </p>

            <p>10K+ Businesses Successfully ISO Certified</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
             <img src={iso} alt='Iso certified'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>ISO Certification Made Simple with T2F</h6>
          <p className='text-center mt-4'>Ready to enhance your business's credibility and operational efficiency? T2F simplifies the ISO certification process, guiding you through every step to ensure compliance with international standards. Contact us today to start your ISO certification journey.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is ISO Certification?</h6>

            <p className='mb-4'>ISO certification demonstrates that your business complies with international standards for quality, safety, and efficiency. It provides a structured approach to quality management, improving your processes and boosting your reputation in the global market.</p>
            <div>
              <ul><strong>Global Recognition:</strong> ISO certification is internationally recognized and enhances your global credibility.</ul>

              <ul><strong>Quality Assurance:</strong> Ensures your processes meet rigorous international standards.</ul>

              <ul><strong>Customer Satisfaction:</strong> Improves customer satisfaction through consistent quality and service.</ul>
            </div>

            <p>ISO certification is crucial for businesses aiming to establish trust and demonstrate commitment to quality and efficiency.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for ISO Certification</h6>
            <p className='mb-4'>To obtain ISO certification, you need to meet the following criteria:

              <ul>
                <li><strong>Established Processes -</strong> Your business must have established and documented processes.</li>

                <li><strong>Compliance with Standards -</strong> Processes should comply with the specific ISO standard you are applying for.</li>

                <li ><strong>Management Commitment -</strong> Demonstrate commitment from top management to uphold ISO standards.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of ISO Certification</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of ISO Certification</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of ISO Certification</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='why' className='secondLLP pr-2 pl-16 mt-16'>
          <div className='Choosing'>
            <h6 className='text-blue-500 text-lg font-semibold'>Choosing the Right ISO Standard</h6>
            <ul className='space-x-4'>
              <li><strong>Relevant Standard - </strong> Ensure you choose the ISO standard that best fits your business needs.</li>

              <li><strong>Alignment - </strong> Your processes and documentation should align with the selected ISO standard.</li>

              <li ><strong>Preparedness -</strong> Make sure your organization is ready for the certification audit.</li>
            </ul>
          </div>

          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for ISO Certification</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='how' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Obtain ISO Certification</h2>
              <p>Follow these steps for a successful ISO certification process:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your ISO Certification</h6>
            <p>T2F is your trusted partner for ISO certification:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Guidance - </strong> Professional support throughout the ISO certification process.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and organizing the necessary documents.</li>

              <li ><strong>Efficient Processing -</strong> Streamlined handling of your ISO certification application.</li>

              <li ><strong>Ongoing Support -</strong> Continuous support and advice throughout the certification journey.</li>

              <li ><strong>Compliance Monitoring -</strong> Assistance with maintaining compliance and preparing for audits.</li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default ISOCertificationNavbar;
