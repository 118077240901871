import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import Copyright from './servicesIMG/Copyrightregis.webp'

const CopyrightRegistration = ({ height }) => {

  // Copyright registration grid items
  const items = [
    { title: 'What is Copyright?', description: 'Copyright is the legal right given to the creator of original works like literary, artistic, musical, or dramatic pieces to control the use of their creation for a limited time.' },
    { title: 'Types of Works', description: 'Copyright protects various forms of works including literary works, software, art, music, films, and architectural designs.' },
    { title: 'Registration Process', description: 'Copyright registration ensures that the creator’s intellectual property rights are protected. The process involves filing an application, submitting the work, and obtaining a copyright certificate.' },
    { title: 'Copyright Duration', description: 'The copyright protection lasts for the lifetime of the author plus an additional 60 years after their death for literary, dramatic, musical, and artistic works.' },
    { title: 'Infringement & Remedies', description: 'Copyright infringement occurs when someone uses the copyrighted work without permission. Legal remedies include damages and injunctions to stop the infringing activity.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Exclusive Rights - Copyright gives the creator the exclusive right to reproduce, distribute, perform, and display their work.",
    "Legal Protection - Registration offers legal protection against unauthorized use of the work.",
    "Economic Benefits - Copyright holders can earn royalties from their work, providing an additional revenue stream.",
    "Moral Rights - It protects the moral rights of creators, ensuring they are properly credited for their work.",
    "Encourages Creativity - Copyright incentivizes creativity by ensuring creators benefit from their original works."
  ];

  const disadvantages = [
    "Limited Duration - Copyright protection is time-bound and does not last indefinitely.",
    "Costly Legal Action - Pursuing legal action for copyright infringement can be expensive and time-consuming.",
    "Restricted Access - Excessive copyright protections can limit access to knowledge and information.",
    "Global Enforcement - Enforcing copyright laws across different jurisdictions can be challenging.",
    "Complexities in Licensing - Licensing and rights management can be complicated, especially for large-scale or collaborative works."
  ];

  const documents = [
    {
      title: "Proof of Ownership",
      description: "Proof of creation, such as drafts, original files, or any other evidence that supports the creation of the work."
    },
    {
      title: "Identity Proof",
      description: "A valid ID proof like Aadhaar card, PAN card, or passport of the creator or applicant."
    },
    {
      title: "Copy of Work",
      description: "A clear and complete copy of the work for which the copyright is being applied."
    },
    {
      title: "No Objection Certificate (NOC)",
      description: "NOC from other creators, if applicable, in cases of joint ownership or collaborative works."
    },
    {
      title: "Power of Attorney",
      description: "If an agent is applying on behalf of the creator, a Power of Attorney document is required."
    }
  ];

  const steps = [
    {
      title: "Submit Application",
      description: "File the copyright application online or offline with the relevant authorities."
    },
    {
      title: "Submit Work",
      description: "Submit a copy of the work to be copyrighted along with the necessary documents."
    },
    {
      title: "Application Review",
      description: "The copyright office will review the application and documents for authenticity."
    },
    {
      title: "Issue of Certificate",
      description: "Upon approval, a copyright certificate is issued, confirming legal protection for the work."
    },
    {
      title: "Legal Protection",
      description: "Once registered, the creator can enforce their rights in case of infringement."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Copyright Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Copyright is a legal protection provided to the creators of original works, allowing them to control the use and distribution of their creations.
            </p>

            <p>Ensure your creative work is protected by registering it with the copyright office.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
             <img src={Copyright} alt='Copyright Registration'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Copyright Registration</h6>
          <p className='text-center mt-4'>Copyright registration grants legal protection to original works, enabling the creator to control how their work is used and distributed.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Copyright?</h6>

            <p className='mb-4'>Copyright provides the creator of an original work with exclusive rights over the use and distribution of their creation, encouraging creativity and innovation.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Copyright Registration</h6>
            <p className='mb-4'>Copyright can be registered for original works like literary, dramatic, musical, and artistic creations, provided the work is fixed in a tangible medium of expression.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Copyright Registration</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Copyright Registration</h6>
            <div className="flex flex-wrap justify-between mt-10">

              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>

              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Copyright Registration</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Copyright Registration</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default CopyrightRegistration;