import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import Patentregis from './servicesIMG/Patentregis.webp'

const PatentNavbar = ({ height }) => {

  // Patent Registration service grid items
  const items = [
    { title: 'Exclusive Rights', description: 'Grants exclusive rights to the inventor to prevent others from making, using, or selling the invention.' },
    { title: 'Legal Protection', description: 'Provides legal protection for your invention against infringement.' },
    { title: 'Market Advantage', description: 'Offers a competitive edge in the market by protecting your innovative ideas.' },
    { title: 'Licensing Opportunities', description: 'Allows licensing of the patent to others, creating potential revenue streams.' },
    { title: 'Encourages Innovation', description: 'Encourages further research and development by securing intellectual property.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Exclusive Rights - Patent registration provides exclusive rights to the inventor, preventing others from using or profiting from the invention without permission.",
    "Legal Protection - Offers legal protection and a mechanism to enforce your rights if someone infringes on your patent.",
    "Market Advantage - Provides a significant competitive edge by protecting your innovative ideas and preventing market competition.",
    "Licensing Opportunities - Enables you to license your patent to other companies or individuals, potentially generating additional income.",
    "Encourages Innovation - Secures intellectual property rights, promoting further innovation and development in the field.",
  ];

  const disadvantages = [
    "Cost - The process of obtaining a patent can be expensive, including application fees, legal costs, and maintenance fees.",
    "Time-Consuming - Patent registration can be a lengthy process, often taking several months to years to complete.",
    "Disclosure of Information - Requires public disclosure of the invention, which may expose details to competitors.",
    "Geographical Limitations - Patents are generally limited to specific countries or regions, requiring separate applications for international protection.",
  ];

  const documents = [
    {
      title: "Patent Application Form",
      description: "Complete the official patent application form provided by the patent office."
    },
    {
      title: "Detailed Description of the Invention",
      description: "Provide a comprehensive description of the invention, including its functionality and how it works."
    },
    {
      title: "Claims of the Invention",
      description: "Draft and include claims that define the scope of the invention and its unique features."
    },
    {
      title: "Drawings/Diagrams",
      description: "Submit technical drawings or diagrams that illustrate the invention, if applicable."
    },
    {
      title: "Proof of Inventorship",
      description: "Provide evidence of the inventors and their contributions to the invention."
    }
  ];

  const steps = [
    {
      title: "Prepare Documentation",
      description: "Gather and prepare all necessary documents and information required for the patent application."
    },
    {
      title: "File Patent Application",
      description: "Submit the patent application along with required documents to the patent office."
    },
    {
      title: "Examination Process",
      description: "Undergo the examination process where the patent office reviews the application for patentability."
    },
    {
      title: "Respond to Objections",
      description: "Address any objections or requests for additional information raised by the patent examiner."
    },
    {
      title: "Grant of Patent",
      description: "Upon successful review, receive the patent grant and enjoy exclusive rights to your invention."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top":`${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Patent Registration
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Patent registration is a legal process that grants exclusive rights to inventors for their inventions, protecting their ideas from unauthorized use. It ensures that the inventor can benefit from their innovation and prevents others from exploiting the invention without permission.
            </p>

            <p>Thousands of inventors and companies have secured their patents with our help.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={Patentregis} alt='PatentRegistartion'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Patent Registration Simplified</h6>
          <p className='text-center mt-4'>Patent registration is a vital step for protecting your innovations and gaining exclusive rights to your inventions. We make the process straightforward and efficient, guiding you through each stage of the application.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Patent Registration?</h6>

            <p className='mb-4'>Patent registration is the process of securing legal rights to an invention, granting the inventor exclusive rights to make, use, and sell the invention for a specific period. This process helps protect intellectual property and provides a competitive advantage in the market.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Patent Registration</h6>
            <p className='mb-4'>To apply for a patent, you must meet the following requirements:

              <ul>
                <li><strong>Patent Application Form -</strong> Complete the official patent application form provided by the patent office.</li>

                <li><strong>Detailed Description of the Invention -</strong> Provide a comprehensive description of the invention, including its functionality and how it works.</li>

                <li><strong>Claims of the Invention -</strong> Draft and include claims that define the scope of the invention and its unique features.</li>

                <li><strong>Drawings/Diagrams -</strong> Submit technical drawings or diagrams that illustrate the invention, if applicable.</li>

                <li><strong>Proof of Inventorship -</strong> Provide evidence of the inventors and their contributions to the invention.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Patent Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Patent Registration</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Patent Registration</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for Patent Registration</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for Patent Registration</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default PatentNavbar;