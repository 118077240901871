import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import Insur from './servicesIMG/InsuranceCOmp.webp'

const InsuranceCompany = ({ height }) => {

  // Insurance Company grid items
  const items = [
    { title: 'What is an Insurance Company?', description: 'An Insurance Company provides coverage and financial protection to individuals and businesses against various risks in exchange for premium payments.' },
    { title: 'Types of Insurance Companies', description: 'Insurance companies offer various types of policies, including life, health, property, and casualty insurance, each catering to different needs.' },
    { title: 'Features of Insurance Companies', description: 'Insurance companies provide risk management, financial security, and peace of mind through a range of insurance products and services.' },
    { title: 'Eligibility for Insurance Companies', description: 'Insurance companies must comply with regulatory requirements, including obtaining licenses and meeting capital requirements as per the insurance regulatory authority.' },
    { title: 'Regulatory Framework', description: 'Insurance companies are regulated by specific laws and guidelines to ensure they operate fairly, maintain solvency, and protect policyholders.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Financial Protection - Provides coverage against various risks and uncertainties.",
    "Risk Management - Helps individuals and businesses manage and mitigate financial risks.",
    "Peace of Mind - Offers assurance and security to policyholders in times of need.",
    "Diverse Products - Offers a wide range of insurance products to meet different needs."
  ];

  const disadvantages = [
    "Premium Costs - Insurance premiums can be expensive depending on the coverage and risk factors.",
    "Complex Policies - Insurance policies can be complex and difficult to understand.",
    "Regulatory Compliance - Insurance companies face stringent regulations and compliance requirements.",
    "Claim Disputes - There may be disputes or delays in claim processing and approval."
  ];

  const documents = [
    {
      title: "Incorporation Certificate",
      description: "Certificate of incorporation issued by the relevant regulatory authority."
    },
    {
      title: "License to Operate",
      description: "Valid license from the insurance regulatory authority to operate as an insurance company."
    },
    {
      title: "Financial Statements",
      description: "Detailed financial statements demonstrating the company's financial health and solvency."
    },
    {
      title: "Business Plan",
      description: "A comprehensive business plan outlining the insurance company's products, market strategy, and financial projections."
    },
    {
      title: "Risk Assessment Reports",
      description: "Reports assessing the risks covered by the insurance policies and the company's risk management strategies."
    }
  ];

  const steps = [
    {
      title: "Obtain License",
      description: "Apply for and obtain a license from the insurance regulatory authority to operate legally."
    },
    {
      title: "Draft Policies",
      description: "Develop and draft insurance policies that meet regulatory requirements and customer needs."
    },
    {
      title: "Set Up Operations",
      description: "Establish operational processes, including underwriting, claims processing, and customer service."
    },
    {
      title: "Launch Products",
      description: "Introduce and market insurance products to potential customers."
    },
    {
      title: "Maintain Compliance",
      description: "Ensure ongoing compliance with regulatory requirements and maintain financial solvency."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Insurance Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Provide comprehensive financial protection with a well-regulated insurance company.
            </p>

            <p>Protect individuals and businesses from financial risks through a diverse range of insurance products and services.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
             <img src={Insur} alt='InsuranceCompany'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Insurance Company</h6>
          <p className='text-center mt-4'>Offer financial protection and security through a range of well-regulated insurance products.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Insurance Company?</h6>

            <p className='mb-4'>An Insurance Company provides coverage to mitigate financial risks in exchange for premium payments from policyholders.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Insurance Companies</h6>
            <p className='mb-4'>Insurance companies must meet regulatory requirements, including obtaining necessary licenses and maintaining financial stability.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Insurance Companies</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Insurance Companies</h6>
            <div className='flex flex-wrap justify-between'>
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className='mt-4'>
                  {advantages.map((advantage, index) => (
                    <li key={index} className="mb-2">{advantage}</li>
                  ))}
                </ul>
              </div>
              <div className="disadvantages-section mb-10" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className='mt-4'>
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index} className="mb-2">{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Insurance Companies</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Insurance Companies</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>

        </div>
        <div className='pl-16 mt-10'>
        </div>
      </div>
    </>
  );
};

export default InsuranceCompany;