import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import Section8 from './servicesIMG/Section8.webp'

const Section8Navbar = ({ height }) => {

  // Section 8 service grid items
  const items = [
    { title: 'Non-Profit Organization', description: 'Section 8 companies are formed with a charitable or social objective.' },
    { title: 'Tax Benefits', description: 'Offers various tax exemptions for donations and income under specific conditions.' },
    { title: 'Limited Liability', description: 'Provides limited liability to its members, protecting personal assets.' },
    { title: 'Government Regulation', description: 'Subject to strict regulations by the Ministry of Corporate Affairs (MCA).' },
    { title: 'Enhanced Credibility', description: 'More credible than other non-profit organizations like trusts or societies.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Tax Benefits - Eligible for tax exemptions on donations and income under section 12A and 80G.",
    "Limited Liability - Protects the personal assets of members.",
    "Enhanced Credibility - Recognized as a legitimate and structured non-profit by the government.",
    "No Minimum Capital Requirement - Section 8 companies are not required to have a minimum capital."
  ];

  const disadvantages = [
    "Stringent Regulations - Must comply with various MCA regulations and guidelines.",
    "Time-Consuming Process - Registration process is more complex and takes longer than other non-profits.",
    "Limited Profit Distribution - Profits cannot be distributed among members, only used for the charitable purpose."
  ];

  const documents = [
    {
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      title: "Identity Proof",
      description: "Aadhar card, Voter ID, Passport, or Driving License for all directors and members."
    },
    {
      title: "Residential Proof",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      title: "Memorandum of Association (MOA)",
      description: "Clearly stating the charitable or social objective of the company."
    },
    {
      title: "Articles of Association (AOA)",
      description: "Defining the rules and regulations of the company."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificate (DSC)",
      description: "Mandatory for all directors to sign the incorporation forms."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "All directors must have a valid DIN."
    },
    {
      title: "Reserve a Name for Your Section 8 Company",
      description: "The name must clearly reflect the charitable or social objective."
    },
    {
      title: "Draft MOA and AOA",
      description: "Clearly outlining the company’s objectives and rules."
    },
    {
      title: "Submit Form INC-12",
      description: "Apply to the MCA for the Section 8 company license."
    },
    {
      title: "Incorporation Filing",
      description: "Once the license is obtained, submit incorporation forms to the Registrar of Companies."
    },
    {
      title: "Obtain Certificate of Incorporation",
      description: "Once approved, the RoC issues the certificate of incorporation."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Section 8 Company
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Section 8 Company is a non-profit organization dedicated to charitable or social purposes, recognized by the government.
            </p>

            <p>Join 5K+ Non-Profits Trusted with Section 8 Registration</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
             <img src={Section8} alt='Section8'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] ">
          <h6 className='text-2xl text-center font-semibold'>Section 8 Registration with Ease</h6>
          <p className='text-center mt-4'>Starting a non-profit organization? Section 8 companies provide a formal structure with credibility and tax benefits. Our experts at T2F simplify the registration process, ensuring compliance with all legalities and timely filings. Contact us today to embark on your social impact journey.</p>
        </div>

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Section 8 Company?</h6>

            <p className='mb-4'>Section 8 Companies are entities established with the objective of promoting charity, education, religion, or other social causes. They are regulated under the Companies Act, 2013 and offer a formal, structured way to operate as a non-profit organization.</p>
            <div>
              <ul><strong>Non-Profit Organization:</strong> Established with charitable or social objectives.</ul>

              <ul><strong>Tax Benefits:</strong> Enjoy various tax exemptions under Section 12A and 80G.</ul>

              <ul><strong>Government Regulated:</strong> Must adhere to Ministry of Corporate Affairs regulations.</ul>
            </div>

            <p>Section 8 companies operate on the principle of using profits to achieve charitable goals.</p>
          </div>

          <div id="el" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a Section 8 Company, the following criteria must be met:

              <ul>
                <li><strong>Charitable Objective -</strong> Must have a clear objective related to charitable activities.</li>

                <li><strong>Minimum 2 Members -</strong> A minimum of two members are required at the time of registration.</li>

                <li><strong>Directors -</strong> At least two directors are mandatory, and they can also be members.</li>
              </ul>
            </p>
          </div>

          {/* <div id='features' className='flex flex-row flex-wrap w-full  md:grid-cols-2 sm:grid-cols-1 justify-around gap-y-16 p-8'>
            {items.map((item, index) => (
              <Card key={index} title={item.title} description={item.description} />
            ))}
          </div> */}

<div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Udyam Registration</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='advantages w-full flex justify-between'>
            <div className='pro p-14 w-1/2'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages</h6>
              <ul className='p-10 '>
                {advantages.map((advantage, index) => (
                  <li className='adv-li ' key={index}>{advantage}</li>
                ))}
              </ul>
            </div>

            <div className='con p-14 w-1/2'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages</h6>
              <ul className='p-10 '>
                {disadvantages.map((disadvantage, index) => (
                  <li className='adv-li ' key={index}>{disadvantage}</li>
                ))}
              </ul>
            </div>
          </div>

          {/* <div id="why" className='LLP-doc w-full p-20'>
            <h6 className='text-blue-500 text-lg font-semibold'>Required Documentation</h6>
            <div className='doc grid grid-cols-3'>
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div> */}

<div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required For Section 8</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>



          <div id="how" className='LLP-work w-full p-20'>
            <h6 className='text-blue-500 text-lg font-semibold'>How to Register Your Section 8 Company</h6>
            <div className='work w-full'>
              <ol className='w-full p-10'>
                {steps.map((step, index) => (
                  <li className='work-li ' key={index}>{step.title} - {step.description}</li>
                ))}
              </ol>
            </div>
          </div>

          <div id='choose' className=' p-20 w-full'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose Us?</h6>
            <p>With extensive experience in registering Section 8 Companies, our team ensures that your non-profit complies with all legalities and helps you secure necessary certifications like 80G and 12A. From drafting MOA/AOA to obtaining your certificate of incorporation, we handle the complexities so you can focus on making a social impact.</p>
          </div>
      </div>

    </>
  );
};

export default Section8Navbar;