import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import dsc from './servicesIMG/DSC.webp'

const DSCNavbar = ({ height }) => {

  // DSC service grid items
  const items = [
    { title: 'Enhanced Security', description: 'Provides a secure way to sign and authenticate documents electronically.' },
    { title: 'Legal Validity', description: 'Legally recognized for electronic transactions and document signing.' },
    { title: 'Ease of Use', description: 'Simple to use for signing documents and ensuring authenticity.' },
    { title: 'Government Compliance', description: 'Compliant with regulations for digital signatures in various sectors.' },
    { title: 'Quick Issuance', description: 'Fast processing and issuance of the Digital Signature Certificate.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Enhanced Security - Digital signatures provide a high level of security and authenticity for electronic documents.",
    "Legal Validity - Digital signatures are legally recognized and accepted for various types of documents.",
    "Efficient Processing - Streamlines the process of document signing and verification.",
    "Compliance - Meets regulatory requirements for secure electronic transactions.",
  ];

  const disadvantages = [
    "Technical Issues - Potential technical issues or compatibility problems with certain software.",
    "Cost - There may be costs associated with obtaining and renewing the DSC.",
    "Complexity for Some - Might be complex for users unfamiliar with digital signatures.",
  ];

  const documents = [
    {
      title: "Identity Proof",
      description: "A valid government-issued identity proof such as Aadhar card or passport is required."
    },
    {
      title: "Address Proof",
      description: "Proof of residence, such as a utility bill or bank statement, needs to be provided."
    },
    {
      title: "Photographs",
      description: "Recent passport-sized photographs may be required for the application process."
    },
    {
      title: "Business Registration Documents",
      description: "For business DSC, provide documents related to the business registration and PAN."
    },
    {
      title: "PAN Card",
      description: "A valid PAN card of the individual or business is needed for verification."
    }
  ];

  const steps = [
    {
      title: "Collect Documents",
      description: "Gather all necessary documents required for DSC application."
    },
    {
      title: "Choose DSC Type",
      description: "Select the type of DSC (Class 2, Class 3) based on your needs."
    },
    {
      title: "Apply Online",
      description: "Submit the DSC application online through a registered DSC provider."
    },
    {
      title: "Verification",
      description: "Complete the verification process with the DSC provider."
    },
    {
      title: "Receive DSC",
      description: "After successful verification, receive your Digital Signature Certificate."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Registration</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Digital Signature Certificate (DSC) Application
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A Digital Signature Certificate (DSC) is crucial for secure online transactions and document signing, ensuring the authenticity and integrity of electronic communications.
            </p>

            <p>Thousands of businesses and individuals have secured their DSCs with us.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={dsc} alt='Digital Signature'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>DSC Application Made Easy</h6>
          <p className='text-center mt-4'>Obtaining a Digital Signature Certificate (DSC) is essential for secure online transactions and document signing. We guide you through a seamless application process for your DSC.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is DSC?</h6>

            <p className='mb-4'>A Digital Signature Certificate (DSC) is an electronic form of signature used to authenticate documents and transactions online. It ensures the security and integrity of digital communications and is legally accepted for various purposes.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for DSC Application</h6>
            <p className='mb-4'>To apply for a DSC, you must meet the following requirements:

              <ul>
                <li><strong>Identity Proof -</strong> Provide a valid government-issued identity proof such as an Aadhar card or passport.</li>

                <li><strong>Address Proof -</strong> Submit proof of residence, such as a utility bill or bank statement.</li>

                <li><strong>Photographs -</strong> Recent passport-sized photographs may be required.</li>

                <li><strong>Business Registration Documents -</strong> For business DSC, include documents related to business registration and PAN.</li>
              </ul>
            </p>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of DSC</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of DSC</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of DSC</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 px-16'>
            <h2>Documents Required for DSC Application</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="how" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Apply for DSC</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default DSCNavbar;