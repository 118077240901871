import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
// import './LLPcardgrid.css';
import "./BackupCss.css"
import "./BackupCss1.css"

const DirectorChange = ({ height }) => {

  // Director Change grid items
  const items = [
    { title: 'What is Director Change?', description: 'Director change involves updating the board of directors of a company with the relevant authorities.' },
    { title: 'Why Change Directors?', description: 'Director changes might be needed due to retirement, resignation, or appointment of new directors.' },
    { title: 'Key Features of Director Change', description: 'Updating directors ensures accurate representation of the company’s leadership in official records.' },
    { title: 'Who Needs Director Change?', description: 'Companies needing to update their board due to internal changes, legal requirements, or business strategy shifts.' },
    { title: 'Legal Requirements', description: 'The change must be filed with the Registrar of Companies and updated in the company’s records.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Updated Leadership - Reflects current leadership, ensuring accurate representation.",
    "Legal Compliance - Keeps the company in compliance with legal and regulatory requirements.",
    "Enhanced Management - Allows for strategic changes in management to align with business goals.",
    "Transparency - Provides clarity and transparency in the company's leadership structure."
  ];

  const disadvantages = [
    "Administrative Burden - The process can be complex and time-consuming.",
    "Potential Disruptions - Leadership changes might cause temporary disruptions in company operations.",
    "Legal Fees - There may be costs associated with filing and updating records.",
    "Transition Challenges - New directors might face a learning curve or integration challenges."
  ];

  const documents = [
    {
      title: "Board Resolution",
      description: "A board resolution approving the appointment or resignation of directors is required."
    },
    {
      title: "Form DIR-12",
      description: "Filing Form DIR-12 with the Registrar of Companies to notify about the director change."
    },
    {
      title: "Director's Consent",
      description: "Written consent from the new director accepting the appointment."
    },
    {
      title: "Resignation Letter",
      description: "If applicable, a resignation letter from the outgoing director must be provided."
    },
    {
      title: "Updated Certificates",
      description: "Updated company certificates reflecting the new board structure should be submitted."
    }
  ];

  const steps = [
    {
      title: "Board Meeting",
      description: "Hold a board meeting to approve the director change and pass a resolution."
    },
    {
      title: "Prepare Documents",
      description: "Prepare necessary documents, including Form DIR-12 and board resolutions."
    },
    {
      title: "File with ROC",
      description: "Submit the documents and forms to the Registrar of Companies for approval."
    },
    {
      title: "Update Records",
      description: "Update the company’s records and certificates to reflect the new directors."
    },
    {
      title: "Notify Stakeholders",
      description: "Inform all relevant stakeholders, including employees and partners, about the director change."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#reg" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#el' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#how" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Director Change
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Update your company’s board of directors efficiently with our expert support.
            </p>

            <p>Ensure your company's leadership records are current and compliant with regulatory requirements.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Director Change</h6>
          <p className='text-center mt-4'>Update your board of directors with our seamless process and ensure compliance with all legal requirements.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Director Change?</h6>

            <p className='mb-4'>Director change refers to updating the board of directors of a company, including new appointments or resignations.</p>
          </div>

          <div id="el" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Who Needs Director Change?</h6>
            <p className='mb-4'>Companies that need to update their board of directors due to internal changes, legal requirements, or business strategy adjustments.</p>
          </div>

          <div id="features" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Director Change</h6>
            <div className="LLP-grid-container mt-10">
              {items.map((item, index) => (
                <Card key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id="pro/con" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Pros & Cons of Director Change</h6>
            <div className='flex flex-wrap justify-between'>
              <div className="advantages-section mb-10" style={{ width: '45%' }}>
                <h5>Advantages</h5>
                <ul className="advantages-list">
                  {advantages.map((advantage, index) => (
                    <li key={index}>{advantage}</li>
                  ))}
                </ul>
              </div>
              <div className="disadvantages-section" style={{ width: '45%' }}>
                <h5>Disadvantages</h5>
                <ul className="disadvantages-list">
                  {disadvantages.map((disadvantage, index) => (
                    <li key={index}>{disadvantage}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="why" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Director Change</h6>
            <div className="LLP-grid-container mt-10">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id="how" className='w-full px-16 mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Director Change</h6>
            <div className="LLP-grid-container mt-10">
              {steps.map((step, index) => (
                <Card key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default DirectorChange;