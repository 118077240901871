import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import PatentS from './servicesIMG/PatentSearch.webp'
const PatentSearchAndProvisionalApplication = ({ height }) => {

  // Patent Search and Provisional Application grid items
  const items = [
    { title: 'Patent Search', description: 'Conduct a comprehensive search to identify existing patents and assess the novelty of your invention.' },
    { title: 'Provisional Patent Application', description: 'File a provisional patent application to establish an early filing date and secure your invention.' },
    { title: 'Drafting Patent Claims', description: 'Prepare detailed patent claims to define the scope of your invention.' },
    { title: 'Patent Attorney Consultation', description: 'Consult with a patent attorney to ensure all aspects of your invention are protected.' },
    { title: 'Filing Process Guidance', description: 'Guidance on the filing process, including submission requirements and timelines.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Comprehensive Search - Identify prior patents and assess the novelty of your invention.",
    "Early Filing Date - Secure your invention with a provisional patent application.",
    "Expert Drafting - Professional assistance in drafting detailed and effective patent claims.",
    "Legal Consultation - Access to experienced patent attorneys for guidance and advice.",
    "Streamlined Process - Efficient filing process with clear guidance and support.",
  ];

  const disadvantages = [
    "Complex Procedure - Patent search and application filing can be complex and require expert knowledge.",
    "Potential Costs - Associated costs for patent search, drafting, and legal consultations.",
    "Time-Consuming - The process can be time-consuming, requiring detailed preparation and documentation.",
  ];

  const documents = [
    {
      title: "Patent Search Report",
      description: "A detailed report of existing patents related to your invention."
    },
    {
      title: "Provisional Patent Application Form",
      description: "The official form for filing a provisional patent application with detailed descriptions of your invention."
    },
    {
      title: "Detailed Description of Invention",
      description: "A comprehensive description of your invention including its features and functionality."
    },
    {
      title: "Patent Claims",
      description: "Claims defining the scope and unique aspects of your invention."
    },
    {
      title: "Supporting Documents",
      description: "Additional documents such as drawings, prototypes, or research data related to your invention."
    }
  ];

  const steps = [
    {
      title: "Conduct Patent Search",
      description: "Perform a thorough search to identify existing patents and evaluate the uniqueness of your invention."
    },
    {
      title: "Prepare Provisional Application",
      description: "Draft and prepare the provisional patent application form with detailed information about your invention."
    },
    {
      title: "File Provisional Application",
      description: "Submit the provisional patent application to the relevant patent office."
    },
    {
      title: "Consult with Patent Attorney",
      description: "Seek advice from a patent attorney to review your application and ensure comprehensive protection."
    },
    {
      title: "Monitor Application Status",
      description: "Track the status of your application and respond to any queries or additional requirements from the patent office."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#search" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#application' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Application</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pro/con" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#process" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Process</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Patent Search and Provisional Application Filing
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Patent Search and Provisional Application Filing are crucial steps in protecting your invention. Our services guide you through a comprehensive patent search and assist with filing a provisional patent application to secure your intellectual property rights.
            </p>

            <p>Ensure your invention is protected with our expert assistance in patent search and provisional application filing.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={PatentS} alt='Patent Search'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="search" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Patent Search and Provisional Application Filing</h6>
          <p className='text-center mt-4'>Patent Search helps identify prior patents and assess your invention's uniqueness. Provisional Application Filing secures an early filing date and establishes your intellectual property rights.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Patent Search and Provisional Application Filing?</h6>

            <p className='mb-4'>Patent Search involves a thorough investigation of existing patents to evaluate the novelty and potential patentability of your invention. Filing a Provisional Patent Application allows you to establish an early filing date while you finalize your invention and prepare a complete patent application.</p>
          </div>

          <div id="application" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Provisional Patent Filing</h6>
            <p className='mb-4'>To file a provisional patent application, you need to prepare and submit the following:

              <ul>
                <li><strong>Patent Search Report -</strong> A comprehensive report identifying existing patents related to your invention.</li>

                <li><strong>Provisional Patent Application Form -</strong> The official form with detailed descriptions of your invention.</li>

                <li><strong>Detailed Description of Invention -</strong> A thorough description of your invention, including its features and functionality.</li>

                <li><strong>Patent Claims -</strong> Claims outlining the scope and unique aspects of your invention.</li>

                <li><strong>Supporting Documents -</strong> Additional materials such as drawings, prototypes, or research data.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='llp-card-grid flex flex-wrap' >
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Key Features of Our Patent Search and Provisional Application Filing Services</h6>
            <div className='flex flex-wrap justify-around items-center'>
              {items.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div id='pro/con' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Advantages of Our Patent Search and Provisional Application Filing Services</h6>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h6 className='text-blue-500 text-lg font-semibold'>Disadvantages of Our Patent Search and Provisional Application Filing Services</h6>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='why-choose mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Required for Patent Search and Provisional Application Filing</h6>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="process" className='how-to-apply mt-16 px-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Steps to Complete Patent Search and Provisional Application Filing</h6>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>

    </>
  );
};

export default PatentSearchAndProvisionalApplication;