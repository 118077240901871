import React, { useState } from "react";
import OtpModal from "./OtpPopup";
import ArrowDownIcon from "./icons/ArrowDown";

const HeroSection = ({ title, ctaText, imagePath, children }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="containerhome">
      <div
        fluid
        className="flex space-x-4  md:flex-row flex-col justify-between items-center bg-[#E3EEFB] px-4 sm:pt-4 md:px-16 py-3"
      >
        <div className="flex-1">
          <h1 className="font-bold sm:w-full text-3xl  md:text-3xl">{title}</h1>

          {children}

          <button
            className="flex bg-[#125B9A] py-[14px] px-4 rounded-lg space-x-4 text-white items-center mt-4"
            onClick={handleShow}
          >
            <span className="text-base font-medium">{ctaText}</span>
            <span className="-rotate-90">
              <ArrowDownIcon color="#ffffff" />
            </span>
          </button>
        </div>

        <div className="flex-1">
          <div className="w-full   bg-[#C6D9F1] rounded-lg sm:w-[480x] mt-3 mg:w-[632px] ">
            <img src={imagePath} alt="homepage" />
          </div>
        </div>
        {/* Render the OTP Modal */}
        <OtpModal showModal={showModal} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default HeroSection;
