import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const CibilComplaints = ({ height }) => {

  // CIBIL Complaints grid items
  const items = [
    { title: 'Dispute Resolution', description: 'We help you resolve disputes related to incorrect credit information on your CIBIL report.' },
    { title: 'Credit Score Improvement', description: 'Our team works to correct errors and improve your credit score effectively.' },
    { title: 'Legal Support', description: 'Get expert legal support to handle complaints and issues related to your CIBIL score.' },
    { title: 'End-to-End Assistance', description: 'We provide complete assistance, from identifying issues to resolving them with CIBIL.' },
    { title: 'Faster Resolutions', description: 'Our streamlined approach ensures quicker resolutions for your complaints and disputes.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Error Correction - Ensures incorrect information on your CIBIL report is corrected efficiently.",
    "Credit Score Boost - Helps in improving your credit score by resolving disputes effectively.",
    "Expert Assistance - Access to financial and legal experts to manage your complaints.",
    "Stress-Free Process - We handle all interactions with CIBIL on your behalf, ensuring a hassle-free experience.",
    "Quick Resolutions - Timely resolution of your complaints and disputes to prevent further impact on your score."
  ];

  const disadvantages = [
    "Lengthy Process - Sometimes, resolving disputes with CIBIL can take longer than expected.",
    "Limited Control - The process is dependent on third-party actions, and there may be delays.",
    "Potential Rejections - If CIBIL or creditors do not agree with the dispute, the correction might be denied.",
    "Legal Costs - Some cases may require additional legal support, which can increase costs.",
    "No Immediate Changes - Changes to your credit report may take time to reflect, affecting urgent credit needs."
  ];

  const documents = [
    {
      title: "CIBIL Report",
      description: "Provide a copy of your CIBIL report to identify errors and disputes."
    },
    {
      title: "Dispute Evidence",
      description: "Submit supporting documents that highlight the errors in your credit report."
    },
    {
      title: "Identity Proof",
      description: "Provide a valid identity proof to verify your identity for the complaint process."
    },
    {
      title: "Financial Statements",
      description: "Include any bank statements or financial records that support your dispute."
    },
    {
      title: "Loan Details",
      description: "Provide details of loans or credit accounts in dispute for quicker resolution."
    }
  ];

  const steps = [
    {
      title: "Initial Consultation",
      description: "Contact us to discuss your CIBIL issues and provide necessary details about your credit report."
    },
    {
      title: "Document Collection",
      description: "Submit required documents and evidence to support your dispute against the errors."
    },
    {
      title: "Filing a Complaint",
      description: "We will file an official complaint with CIBIL and initiate the dispute resolution process."
    },
    {
      title: "Follow-up",
      description: "Our team will follow up with CIBIL to ensure timely correction and resolution of the dispute."
    },
    {
      title: "Resolution Confirmation",
      description: "Once resolved, we will confirm the corrections and update you on the status of your credit score."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Resolve Your CIBIL Complaints with Ease
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Facing issues with your CIBIL report? We offer expert assistance in resolving disputes, improving your credit score, and handling all communication with CIBIL on your behalf.
            </p>

            <p>Our team ensures that your complaints are handled quickly and efficiently, giving you peace of mind.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Quick and Hassle-Free CIBIL Complaint Resolution</h6>
          <p className='text-center mt-4'>We help you resolve complaints and disputes related to your CIBIL report, ensuring that errors are corrected and your credit score is improved in a timely manner.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a CIBIL Complaint?</h6>

            <p className='mb-4'>A CIBIL complaint refers to an issue or dispute related to incorrect or outdated information on your credit report. This could impact your credit score and ability to obtain loans. We assist in resolving these complaints by filing disputes with CIBIL on your behalf.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Filing CIBIL Complaints</h6>
            <p className='mb-4'>To be eligible for filing a CIBIL complaint, you must meet the following requirements:

              <ul>
                <li><strong>Inaccurate Credit Report -</strong> You must have a valid reason to believe that your CIBIL report contains inaccurate or outdated information.</li>

                <li><strong>Valid Documentation -</strong> You should provide supporting documents to prove the inaccuracies in your credit report.</li>

                <li><strong>Identity Verification -</strong> You must provide identity proof to validate your complaint with CIBIL.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Our CIBIL Complaint Services</h6>

            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}
            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Our CIBIL Complaint Resolution Services</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of CIBIL Complaints</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Filing a CIBIL Complaint</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps for Filing a CIBIL Complaint</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for CIBIL Complaint Resolution?</h6>
            <p>We provide expert assistance to resolve your CIBIL disputes and ensure that your credit report is corrected efficiently. Our legal and financial experts make the entire process smooth and hassle-free for you.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default CibilComplaints;