import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const WindingUpLLP = ({ height }) => {

  // Winding Up of LLP grid items
  const items = [
    { title: 'Legal Compliance', description: 'Ensure all legal requirements are met during the winding-up process.' },
    { title: 'Debt Settlement', description: 'Proper settlement of all outstanding debts and obligations.' },
    { title: 'Asset Liquidation', description: 'Efficient liquidation of company assets to pay off liabilities.' },
    { title: 'Regulatory Filings', description: 'Complete all necessary regulatory filings and notifications.' },
    { title: 'Final Accounts Preparation', description: 'Prepare and finalize accounts to conclude the LLP’s financial activities.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Legal Compliance - Ensures all legal procedures and requirements are followed during the winding-up process.",
    "Debt Settlement - Effective settlement of all outstanding debts and obligations.",
    "Asset Liquidation - Efficient liquidation of company assets to pay off liabilities.",
    "Regulatory Filings - Ensures all required regulatory filings and notifications are completed.",
    "Final Accounts - Preparation and finalization of accounts to wrap up financial activities."
  ];

  const disadvantages = [
    "Complex Process - Winding up can be a complex and lengthy process involving various legal and financial steps.",
    "Cost - The process may involve costs for legal and accounting services.",
    "Time-Consuming - It can be time-consuming, impacting the business's operations during the process.",
    "Regulatory Hurdles - There may be multiple regulatory requirements and paperwork to handle.",
    "Asset Realization - Liquidating assets may not always fetch the expected value, affecting the settlement process."
  ];

  const documents = [
    {
      title: "Resolution for Winding Up",
      description: "A formal resolution passed by the partners to initiate the winding-up process."
    },
    {
      title: "Financial Statements",
      description: "Recent financial statements to assess the company's financial position and liabilities."
    },
    {
      title: "List of Creditors",
      description: "A comprehensive list of all creditors and outstanding obligations."
    },
    {
      title: "Asset Inventory",
      description: "An inventory of all company assets to be liquidated or distributed."
    },
    {
      title: "Regulatory Forms",
      description: "Completed forms required for regulatory filings and notifications."
    }
  ];

  const steps = [
    {
      title: "Pass Winding Up Resolution",
      description: "The partners must pass a resolution to commence the winding-up process."
    },
    {
      title: "Settle Debts and Liabilities",
      description: "Identify and settle all outstanding debts and obligations of the LLP."
    },
    {
      title: "Liquidate Assets",
      description: "Sell or distribute the company’s assets to pay off liabilities."
    },
    {
      title: "File Regulatory Forms",
      description: "Submit necessary regulatory forms and notifications to relevant authorities."
    },
    {
      title: "Prepare Final Accounts",
      description: "Finalize and prepare the final accounts of the LLP to complete the winding-up process."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Winding Up of LLP
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Efficiently wind up your LLP with our expert guidance. We handle legal compliance, debt settlement, asset liquidation, and regulatory filings to ensure a smooth and compliant winding-up process.
            </p>

            <p>Winding up an LLP involves several complex steps including legal compliance, settlement of debts, and liquidation of assets. Our services simplify this process, ensuring all requirements are met and the process is handled efficiently.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Efficient Winding Up of LLP</h6>
          <p className='text-center mt-4'>We provide comprehensive services to assist with the winding-up of LLPs, ensuring legal compliance, proper debt settlement, and efficient asset liquidation. Trust us to handle the complexities and streamline the process for you.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Winding Up of LLP?</h6>

            <p className='mb-4'>Winding up of an LLP is the process of closing down the business, settling all debts, liquidating assets, and completing regulatory requirements. It involves several legal and financial steps to ensure the company is closed properly and all obligations are met.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To wind up an LLP, the following are required:

              <ul>
                <li><strong>Resolution for Winding Up -</strong> A formal resolution passed by the partners to start the process.</li>

                <li><strong>Financial Statements -</strong> Current financial statements to assess liabilities and assets.</li>

                <li><strong>List of Creditors -</strong> A comprehensive list of creditors and outstanding obligations.</li>

                <li><strong>Asset Inventory -</strong> Detailed inventory of all assets to be liquidated or distributed.</li>

                <li><strong>Regulatory Forms -</strong> Required forms for regulatory filings and notifications.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Winding Up of LLP</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 pr-2 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Winding Up of LLP</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Winding Up of LLP</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Winding Up of LLP</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Wind Up an LLP</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Winding Up of LLP?</h6>
            <p>Our team provides expert assistance to ensure a smooth and compliant winding-up process. We manage legal compliance, debt settlement, asset liquidation, and regulatory filings to handle the complexities of winding up efficiently.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default WindingUpLLP;