import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard'; // Assuming Card component is used for feature display
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
import PartnershipLLP from './servicesIMG/4.webp'
import { useParams } from 'react-router-dom';
import { Data } from '../constants';

const PartnershipToLLPNavbar = ({ height }) => {


  const {id}= useParams();

  console.log(Data[id-1]);
  const data=Data[id-1];
  
  // Features, advantages, and process items
  const items = [
    { title: 'Limited Liability', description: 'Partners’ liability is limited to their contribution in the LLP.' },
    { title: 'Separate Legal Entity', description: 'LLP is a separate legal entity from its partners.' },
    { title: 'Flexibility in Management', description: 'Flexibility in the structure and management of the LLP.' },
    { title: 'Tax Benefits', description: 'Possibility of tax advantages compared to traditional partnership firms.' },
    { title: 'Ease of Fundraising', description: 'Easier to attract investment due to the LLP structure.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Limited Liability - Partners’ liability is limited to their contribution in the LLP.",
    "Separate Legal Entity - LLP is a separate legal entity from its partners.",
    "Flexibility in Management - Flexibility in the structure and management of the LLP.",
    "Tax Benefits - Possibility of tax advantages compared to traditional partnership firms.",
    "Ease of Fundraising - Easier to attract investment due to the LLP structure."
  ];

  const disadvantages = [
    "Regulatory Compliance - LLPs are subject to regulatory compliance and formalities.",
    "Cost of Conversion - Initial costs associated with conversion may be higher.",
    "Complex Structure - Transitioning from a partnership to an LLP may involve complex legal processes.",
    "Ongoing Compliance - Requires ongoing compliance with LLP regulations and filing requirements."
  ];

  const documents = [
    {
      title: "Partnership Deed",
      description: "Copy of the existing partnership deed."
    },
    {
      title: "Certificate of Incorporation",
      description: "Certificate of incorporation of the new LLP."
    },
    {
      title: "Board Resolutions",
      description: "Resolutions passed by partners agreeing to the conversion."
    },
    {
      title: "Proof of Address",
      description: "Proof of address of the registered office of the LLP."
    },
    {
      title: "Identity Proof of Partners",
      description: "Identity proof of all the partners involved."
    },
    {
      title: "PAN Card",
      description: "Permanent Account Number (PAN) of the LLP."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificates (DSC)",
      description: "Acquire DSCs for all the designated partners of the LLP."
    },
    {
      title: "Obtain Director Identification Number (DIN)",
      description: "Apply for DIN for all the designated partners of the LLP."
    },
    {
      title: "Draft LLP Agreement",
      description: "Prepare the LLP agreement detailing the rights and duties of the partners."
    },
    {
      title: "File for Conversion",
      description: "File an application for conversion with the Ministry of Corporate Affairs (MCA)."
    },
    {
      title: "Obtain Certificate of Conversion",
      description: "Receive the certificate of conversion from the MCA."
    },
    {
      title: "Update Records",
      description: "Update all relevant records and documents with the new LLP details."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documentation</a>
            <a href="#conversion" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Conversion Steps</a>
            <a href="#why-choose" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why choose?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Conversion of Partnership Firm to LLP
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Converting your partnership firm to a Limited Liability Partnership (LLP) can provide enhanced flexibility, limited liability, and tax benefits. T2F simplifies this transition, ensuring a smooth and compliant process for your firm’s conversion.
            </p>

            <p>10K+ Successful Conversions</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '100%',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              <img src={PartnershipLLP} alt='PartnershipLLP'/>
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Conversion of Partnership Firm to LLP</h6>
          <p className='text-center mt-4'>Converting a partnership firm to an LLP offers limited liability protection to its partners while retaining the flexibility of a partnership structure. T2F offers expert guidance through each step of the conversion process to ensure compliance and a smooth transition.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap'>

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Conversion of Partnership Firm to LLP?</h6>

            <p className='mb-4'>Converting a partnership firm into a Limited Liability Partnership (LLP) involves transitioning from a traditional partnership structure to an LLP structure. This conversion provides several benefits including limited liability for partners, separate legal status, and enhanced flexibility in management and operations.</p>
            <div>
              <ul><strong>Limited Liability:</strong> Partners’ liability is limited to their contribution in the LLP.</ul>

              <ul><strong>Separate Legal Entity:</strong> LLP is a separate legal entity from its partners.</ul>

              <ul><strong>Flexibility in Management:</strong> Flexibility in the structure and management of the LLP.</ul>

              <ul><strong>Tax Benefits:</strong> Possibility of tax advantages compared to traditional partnership firms.</ul>

              <ul><strong>Ease of Fundraising:</strong> Easier to attract investment due to the LLP structure.</ul>
            </div>
          </div>

          <div className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of LLP Conversion</h6>
            <div className="card-grid">
              {items.map((item, index) => (
                <Card style={{ backgroundColor: '#C0DEF7' }} key={index} title={item.title} description={item.description} />
              ))}

            </div>
          </div>

          <div id='pros-cons' className='pros'>
            <div className='AdvantagesLLP pl-16 mt-16'>
              <h2>Advantages of Converting to LLP</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index} className="advantage-item">
                    <span className="checkmark">✔️</span> {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='Disadvantages mt-4'>
              <h2>Disadvantages of Converting to LLP</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index} className="disadvantage-item">
                    <span className="crossmark">❌</span> {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id='documents' className='secondLLP pr-2 pl-16 mt-16'>
          <div className='Documents'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Conversion to LLP</h6>
            <p>Here’s what you’ll need:</p>
            <div className="card-grid">
              {documents.map((doc, index) => (
                <Card key={index} title={doc.title} description={doc.description} />
              ))}
            </div>
          </div>

          <div id='conversion' className='Howto mt-16'>
            <div className="registration-steps">
              <h2>How to Convert</h2>
              <p>Follow these steps for a smooth conversion of your partnership firm to an LLP:</p>
              <div className="card-grid">
                {steps.map((step, index) => (
                  <Card
                    key={index}
                    title={`${index + 1}. ${step.title}`}
                    description={step.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your LLP Conversion</h6>
            <p>T2F is your trusted partner for converting your partnership firm to an LLP:</p>
            <ul className='space-x-4'>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you through the conversion process.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and PAN Assistance -</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to legal and regulatory requirements.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the conversion process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipToLLPNavbar;
