import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TaxSavingServices = ({ height }) => {

  // Tax Saving Services grid items
  const items = [
    { title: 'High Returns', description: 'Fixed Deposits and Bonds offer competitive interest rates to maximize your returns.' },
    { title: 'Tax Benefits', description: 'Enjoy tax deductions under Section 80C of the Income Tax Act on investments in specified bonds.' },
    { title: 'Security', description: 'Investments in FDs and Bonds are typically low-risk and secure.' },
    { title: 'Flexible Tenure', description: 'Choose from a variety of tenures that suit your financial goals.' },
    { title: 'Interest Options', description: 'Select from monthly, quarterly, or annual interest payment options.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "High Returns - Fixed Deposits and Bonds offer attractive interest rates for better returns on your investment.",
    "Tax Benefits - Avail tax deductions under Section 80C of the Income Tax Act for investments in certain bonds.",
    "Security - FDs and Bonds are generally considered low-risk investments, providing a safe option for your savings.",
    "Flexible Tenure - Choose from a range of tenures to align with your investment horizon and financial goals.",
    "Interest Options - Options to receive interest payouts monthly, quarterly, or annually as per your preference."
  ];

  const disadvantages = [
    "Liquidity - Fixed Deposits have a lock-in period, and early withdrawals might incur penalties.",
    "Interest Rate Risk - Interest rates on Bonds can be influenced by market conditions, potentially affecting returns.",
    "Inflation Impact - Fixed interest rates may be affected by inflation, impacting the real returns on investment.",
    "Taxability - Interest income from FDs and Bonds is taxable under Income Tax Act provisions.",
    "Limited Growth - Fixed Deposits and Bonds might not provide the same growth potential as equities or mutual funds."
  ];

  const documents = [
    {
      title: "KYC Documents",
      description: "Identity and address proof documents for KYC compliance."
    },
    {
      title: "PAN Card",
      description: "Permanent Account Number (PAN) card for tax purposes."
    },
    {
      title: "Investment Details",
      description: "Details of the investment amount and tenure."
    },
    {
      title: "Bank Account Information",
      description: "Bank account details for interest payouts and transactions."
    },
    {
      title: "Form 15G/15H",
      description: "Forms to ensure no tax deduction at source if eligible."
    }
  ];

  const steps = [
    {
      title: "Choose Investment Type",
      description: "Select between Fixed Deposits or Bonds based on your financial goals."
    },
    {
      title: "Complete KYC",
      description: "Submit necessary KYC documents to comply with regulatory requirements."
    },
    {
      title: "Make Investment",
      description: "Invest the chosen amount for the selected tenure."
    },
    {
      title: "Receive Confirmation",
      description: "Obtain confirmation of your investment and interest rate details."
    },
    {
      title: "Track Returns",
      description: "Monitor your investment and receive periodic interest payouts."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Tax Saving Services through FDs/Bonds
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Invest in Fixed Deposits and Bonds to enjoy significant tax benefits and secure high returns. Our services ensure a smooth investment process with maximum benefits.
            </p>

            <p>Optimize your tax savings and investment returns with our expert guidance on FDs and Bonds.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Tax Saving with FDs/Bonds</h6>
          <p className='text-center mt-4'>Maximize your tax savings and secure high returns with Fixed Deposits and Bonds. Our streamlined investment process helps you leverage tax benefits effectively.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What are Tax Saving Services through FDs/Bonds?</h6>

            <p className='mb-4'>Tax Saving Services through Fixed Deposits (FDs) and Bonds offer a way to save on taxes while earning attractive returns. These investments are a popular choice for individuals looking for safe and effective ways to reduce their taxable income while ensuring capital security.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To invest in FDs and Bonds for tax saving, you need to:

              <ul>
                <li><strong>Complete KYC -</strong> Provide KYC documents to comply with regulatory requirements.</li>

                <li><strong>PAN Card -</strong> Ensure you have a PAN card for tax purposes.</li>

                <li><strong>Investment Details -</strong> Decide on the investment amount and tenure.</li>

                <li><strong>Bank Account -</strong> Provide bank account details for transactions and interest payouts.</li>

                <li><strong>Form 15G/15H -</strong> Submit forms to avoid TDS deductions if eligible.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Tax Saving FDs/Bonds</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Investing in FDs/Bonds for Tax Saving</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Investing in FDs/Bonds</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Tax Saving Services</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Invest in Tax Saving FDs/Bonds</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Tax Saving Investments?</h6>
            <p>Our dedicated team offers expert assistance with investing in Fixed Deposits and Bonds for tax saving. We provide personalized guidance to help you make informed investment decisions and maximize your tax benefits.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default TaxSavingServices;